import { useEffect, useState } from 'react';
import './Input.css';

export default function Input({
  label,
  value,
  name,
  type = 'text',
  autoComplete = 'on',
  onChange,
  isRequired = true,
  readOnly = false,
  error,
  onKeyDown,
}) {
  const [isFocuced, setIsFocuced] = useState(false);

  useEffect(() => {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }, [value]);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleUnfocused(evt) {
    const value = evt.target.value;
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }

  return (
    <div className={`custom-input ${readOnly ? 'custom-input_disabled' : ''}`}>
      <label
        className={`custom-input__label ${
          isFocuced || Boolean(value) ? 'custom-input__label_focused' : ''
        }`}
        htmlFor={name}
      >
        {label}
      </label>

      <input
        className="custom-input__input"
        id={name}
        name={name}
        type={type}
        value={value || ''}
        required={isRequired}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onChange={onChange}
        onClick={!readOnly ? handleFocused : undefined}
        onFocus={!readOnly ? handleFocused : undefined}
        onFocusCapture={handleFocused ?? undefined}
        onBlur={handleUnfocused}
        onKeyDown={onKeyDown ?? undefined}
      />

      {error !== undefined && Boolean(error.errorMessage) && (
        <span
          className="custom-input__error"
          dangerouslySetInnerHTML={{
            __html: error.errorMessage,
          }}
        />
      )}
    </div>
  );
}
