import validator from 'validator';

export default function useEmailValidator() {
  function validateEmail({ value, e }) {
    const validity = {
      errorMessage: '',
      validState: false,
    };

    if (!value) {
      validity.errorMessage = '';
      validity.validState = false;

      return { validity };
    }

    if (value.length >= 2) {
      if (validator.isEmail(value)) {
        validity.errorMessage = '';
        validity.validState = true;
      } else {
        validity.errorMessage = !e.target.validity.valid
          ? e.target.validationMessage
          : 'Invalid email';
        validity.validState = false;
      }
    }

    return { validity };
  }
  return { validateEmail };
}
