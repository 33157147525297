import { motion } from 'framer-motion';
import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import './PartnerCard.css';

export default function PartnerCard({ card }) {
  const { title, img, text, links } = card;

  return (
    <ElementFromOpacity>
      <div className="partner-card">
        <div className="partner-card__img-box">
          <img className="partner-card__img" src={img} alt={title} />
        </div>

        <div className="partner-card__content">
          <p className="partner-card__title">{title}</p>
          <p className="partner-card__text">{text}</p>
          <ul className="partner-card__buttons-list">
            {links?.menu && (
              <li className="partner-card__button-item">
                <motion.a
                  className="partner-card__button partner-card__button_type_color"
                  href={links.menu}
                  target="_blank"
                  rel="noreferrer"
                  whileHover={{ backgroundColor: 'var(--link-button-hover)' }}
                  whileTap={{ backgroundColor: 'var(--link-button-active)' }}
                >
                  Меню
                </motion.a>
              </li>
            )}
            {links?.site && (
              <li className="partner-card__button-item">
                <motion.a
                  className="partner-card__button"
                  href={links.site}
                  target="_blank"
                  rel="noreferrer"
                  whileHover={{
                    borderColor: 'var(--link-button-hover)',
                    color: 'var(--link-button-hover)',
                  }}
                  whileTap={{
                    borderColor: 'var(--link-button-active)',
                    color: 'var(--link-button-active)',
                  }}
                >
                  Сайт
                </motion.a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </ElementFromOpacity>
  );
}
