import { motion } from 'framer-motion';
import { STORAGE_KEY, TARIFF_ID } from '../../assets/utils/constants';
import { scrollToElement } from '../../assets/utils/utils';
import './LinkButton.css';

export default function LinkButton({
  text = 'Купить',
  onClick,
  isStore = false,
  href,
  id,
  slideTo
}) {
  // const swiper = document.querySelector('.swiper')?.swiper;

  function handleClick() {
    // scrollToElement(TARIFF_ID);
    slideTo(12)
    localStorage.setItem(STORAGE_KEY, id);
  }

  return href ? (
    <motion.a
      className="link-button"
      href={href}
      whileHover={{ backgroundColor: 'var(--scroll-button-hover)' }}
      whileTap={{ backgroundColor: 'var(--scroll-button-active)' }}
    >
      {text}
    </motion.a>
  ) : (
    <motion.button
      className={`link-button ${isStore ? 'link-button_buy-screen' : ''}`}
      button="button"
      onClick={onClick ?? handleClick}
      whileHover={{ backgroundColor: 'var(--scroll-button-hover)' }}
      whileTap={{ backgroundColor: 'var(--scroll-button-active)' }}
    >
      {text}
    </motion.button>
  );
}
