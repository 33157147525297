import './TableSchema.css';
import waiter from '../../../assets/images/waiter.svg';
import hook from '../../../assets/images/hook.svg';
import check from '../../../assets/images/bill.svg';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function TableSchema({ data }) {
    const TABLES = new Array(12).fill({});
    const [lastTable, setLastTable] = useState(null);
    const [highlightedTable, setHighlightedTable] = useState(null);

    useEffect(() => {
        if (data?.length > 0) {
            const newLastTable = data[data.length - 1].table;
            setLastTable(newLastTable); 
            setHighlightedTable(newLastTable);
            const timer = setTimeout(() => {
                setHighlightedTable(null)
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [data[data.length - 1]]);

    const barmanTables = data?.filter(item => item.type === 'bar').map(item => item.table) || [];
    const hookahTables = data?.filter(item => item.type === 'hookah').map(item => item.table) || [];
    const checkTables = data?.filter(item => item.type === 'bill').map(item => item.table) || [];

    return (
        <div className="table-schema">
            {TABLES.map((item, i) => {
                const tableNumber = i + 1;
                const isActive = data?.some(city => city.table === tableNumber);
                const isLastTable = tableNumber === lastTable;
                const isHighlighted = tableNumber === highlightedTable;
                const tableStyle = isActive ? { color: '#FFB31F', backgroundColor: isHighlighted ? '#E9E9ED' : '#8B8B9E' } : {};

                return (
                    <motion.div
                        className={`table__box`}
                        key={i}  
                        style={{
                            gridArea: `table${tableNumber}`,
                            ...tableStyle,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: i * 0.1 }}
                    >
                        <div className="table__box__icons">
                            {/* Иконка бармена */}
                            {barmanTables.includes(tableNumber) && (
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt=""
                                    className="table__box__icons__img"
                                    src={waiter}
                                />
                            )}
                            {/* Иконка кальяна */}
                            {hookahTables.includes(tableNumber) && (
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt=""
                                    className="table__box__icons__img"
                                    src={hook}
                                />
                            )}
                            {/* Иконка счета */}
                            {checkTables.includes(tableNumber) && (
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt=""
                                    className="table__box__icons__img"
                                    src={check}
                                />
                            )}
                        </div>
                        {/* Номер стола */}
                        <p className="table__box__numbers" style={{ color: isActive ? '#fff' : '' }}>
                            {tableNumber}
                        </p>
                    </motion.div>
                );
            })}
        </div>
    );
}

export default TableSchema;







