import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import './SubcategoryNav.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import { useState } from 'react';

const SlideContent = ({ currentSubcatId, title, productsByCat, isLast }) => {
  const id = title.toLowerCase().replaceAll(' ', '-');

  return productsByCat?.length > 0 ? (
    <div
      className={`subcat-nav__slide ${isLast ? 'subcat-nav__slide_size_wide' : ''
        }`}
    >
      <a
        className={`subcat-nav__link ${currentSubcatId === id ? 'subcat-nav__link_active' : ''
          }`}
        href={`#${id}`}
      >
        <div
          className={`subcat-nav__mark-box ${currentSubcatId === id ? 'subcat-nav__mark-box_visible' : ''
            }`}
        >
          <div className="subcat-nav__link-mark" />
        </div>
        <p className="subcat-nav__link-text">{title}</p>
      </a>
    </div>
  ) : null;
};

function SubcategoryNav({ categories, products, setSwiper, currentSubcatId,slideTo }) {
  const [positionNav, setPositionNav] = useState(false)

  return (
    <div className={`subcat-nav ${positionNav? '':''}`}>
      <Swiper
        className="subcat-nav__swiper"
        slidesPerView="auto"
        spaceBetween={10}
        freeMode={{ enabled: true, sticky: true }}
        modules={[FreeMode]}
        onInit={(evt) => setSwiper(evt)}
      >
        {categories?.map((item, i) => (
          <SwiperSlide onClick={() => setPositionNav(true)} key={item.id}>
            <SlideContent slideTo={slideTo}
              isLast={i === categories?.length - 1}
              title={item.title}
              productsByCat={products.filter(
                (product) => product?.category?.id === item.id
              )}
              {...{ currentSubcatId }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SubcategoryNav;
