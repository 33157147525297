import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Integrations.css';
import slideImg from '../../../assets/images/options/slide3.webp';
import slideIcon from '../../../assets/icons/options/slide3.png';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Связь QR-кода со столом:',
    text: 'Идентификация столов по QR-коду для&nbsp;точного обслуживания и&nbsp;доставки&nbsp;заказов.',
  },
  {
    title: 'Быстрое обновление контента:',
    text: 'Обновление информации в меню и&nbsp;приложениях в&nbsp;реальном времени.',
  },
];

export default function Integrations({ activeIndex,slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 6) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText} title="Интеграции">
        <img className="integrations__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="integrations__circle-wrapper">
          <Circle color="var(--color-accent-undecenary)" />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="integrations__rhomb-wrapper">
          <RhombWithHole
            color="var(--color-accent-duodecenary)"
            rhombStrokeWidth="21.55%"
          />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="integrations__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-tredecenary)" />
          </ElementFlicker>
        </div>

        <div className="integrations__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-quattuordecenary)"
              rotateAngle="135deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
