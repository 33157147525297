import { useRef, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './ElementRotate.css';
import { ANIMATION_DURATION } from '../../assets/utils/constants';

const animationVariants = {
  hidden: { rotate: 0, opacity: 0 },
  visible: {
    rotate: 360,
    opacity: 1,
    transition: { duration: ANIMATION_DURATION, type: 'spring' },
  },
};

export default function ElementRotate({ children,elementInView }) {
  const elementRef = useRef(null);
  // const elementInView = useInView(elementRef, { once: true });
  const elementControls = useAnimation();
  // const elementInView = useInView({ root: container })

  useEffect(() => {
    if (elementInView) {
      elementControls.start('visible');
    }
  }, [elementInView, elementControls]);

  return (
    <motion.div
      ref={elementRef}
      variants={animationVariants}
      animate={elementControls}
      initial="hidden"
      className="element-rotate"
    >
      {children}
    </motion.div>
  );
}