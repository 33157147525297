import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import './Notifications.css';
import slideImg from '../../../assets/images/options/slide1.webp';
import slideIcon from '../../../assets/icons/options/slide1.png';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Интеграция с устройствами:',
    text: 'Уведомления могут поступать на любые носимые гаджеты через Telegram, позволяя использовать личные устройства сотрудников.',
  },
  {
    title: 'Часы с уведомлениями:',
    text: 'Специальные часы для персонала с&nbsp;возможностью получения уведомлений, что&nbsp;повышает скорость и качество обслуживания.',
  },
];

export default function Notifications({ activeIndex,slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 4) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText} title="Уведомления">
        <img className="notifications__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="notifications__ring-wrapper">
          <Ring color="var(--color-accent-quattuordenary)" />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="notifications__rhomb-wrapper">
          <RhombWithHole
            color="var(--color-accent-primary)"
            rhombStrokeWidth="25.88%"
          />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="notifications__big-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-tertiary)" />
          </ElementFlicker>
        </div>

        <div className="notifications__small-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-quindenary)" />
          </ElementFlicker>
        </div>

        <div className="notifications__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-sexdenary)"
              rotateAngle="45deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
