import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import LinkButton from '../../LinkButton/LinkButton';
import './TextElement.css';

export default function TextElement({ title, text, icon, withScrollBtn, slideTo }) {
  return (
    <ElementFromOpacity>
      <div className="text-element">
        <div className="text-element__title-box">
          <img className="text-element__icon" src={icon} alt="" />
          {title && <p className="text-element__title">{title}</p>}
        </div>
        <ul className="text-element__list">
          {text.map((item) => (
            <li
              className={`text-element__item ${
                withScrollBtn ? 'text-element__item_type_with-btn' : ''
              }`}
              key={item.title ?? item.text}
            >
              <p className="text-element__text">
                <span className="text-element__text_weight_bold">
                  {item.title}
                </span>
                <br />
                <span dangerouslySetInnerHTML={{ __html: item.text }} />
              </p>
            </li>
          ))}
          {withScrollBtn && (
            <li className="text-element__item text-element__item_type_scroll-btn">
              <LinkButton id={'textElement'} slideTo={slideTo}/>
            </li>
          )}
        </ul>
      </div>
    </ElementFromOpacity>
  );
}
