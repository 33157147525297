import './Catalog.css';
import logo from '../../../assets/images/logo.png';
import line from '../../../assets/images/logo-line.png';
import star from '../../../assets/images/star-bg.svg';
import { motion } from "framer-motion"
import { Link, useNavigate, useParams } from 'react-router-dom';
// import mainApi from '../../assets/api/MainApi';
import { useEffect, useState } from 'react';
import { CATALOG, CATALOG_BUTTON, PRODUCTS, TABLES_BILL_LINK_MAP } from '../../../assets/utils/constants';
import { getRandomInt, getRandomNumber } from '../../../assets/utils/utils';
import WaiterConf from '../WaiterConf/WaiterConf';
import Rules from '../Rules/Rules';
import SubcategotyPageTemplate from '../SubcategoryPageTemplate/SubcategoryPageTemplate';
import Header from '../Header/Header';
import Cart from '../Cart/Cart';
import OrderHistory from '../OrderHistory/OrderHistory';
import PayPage from '../PayPage/PayPage';

function Catalog({ catalog, onOpenConf, setSearchParams, searchParams, openMenu, setOpenMenu, openCart, setOpenCart, setAddNew, addNew, setPayOpen, isPayOpen, barActive, setBarActive, setBillActive, billActive, menuActive, setMenuActive, isOpenHistory, setOpenHistory }) {
    // const [openMenu, setOpenMenu] = useState(false);
    const [isRulesOpen, setRulesOpen] = useState(false);
    const [randomNumber, setRandomNumber] = useState(235);
    const [isOpenConf, setIsOpenConf] = useState({
        visible: false,
        type: '',
        icon: ''
    });

    useEffect(() => {
        const id = setInterval(() => {
            setRandomNumber(
                getRandomInt(50, 300)
            );
        }, 6000);

        return () => {
            clearInterval(id);
        };
    }, [randomNumber]);

    function OpenConfirm(item) {
        setIsOpenConf(prevState => ({
            ...prevState,
            visible: true,
            type: item.type,
            icon: item.icon
        }))
    }

    useEffect(() => {
        if (isOpenConf) {
            document.body.style.overflow = "hidden";
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
        }
    }, [isOpenConf]);

    let realTableLocal = JSON.parse(localStorage.getItem('table'));

    function chooseCategory({ id }) {
        setOpenMenu(true)
        setSearchParams(searchParams => {
            searchParams.set("catalog", id);
            return searchParams;
        });
        setBarActive(false)
        setBillActive(false)
    }
    // console.log(isPayOpen)

    return (
        <>
            <WaiterConf setBarActive={setBarActive} setBillActive={setBillActive} setAddNew={setAddNew} addNew={addNew} isOpen={isOpenConf} setIsOpen={setIsOpenConf} />
            <Rules isRulesOpen={isRulesOpen} setRulesOpen={setRulesOpen} />
            {isPayOpen ?
                <PayPage  setBillActive={setBillActive} isPayOpen={isPayOpen} setPayOpen={setPayOpen}/>
                :
                isOpenHistory ?
                    <OrderHistory isOpen={isOpenHistory} setOpen={setOpenHistory} />
                    :
                    openCart ?
                        <Cart setAddNew={setAddNew} setOpenCart={setOpenCart} openCart={openCart} />
                        :
                        openMenu ?
                            <SubcategotyPageTemplate setBarActive={setBarActive} setBillActive={setBillActive} setAddNew={setAddNew} addNew={addNew} setPayOpen={setPayOpen} openCart={openCart} setOpenCart={setOpenCart} isOpen={openMenu} setOpen={setOpenMenu} />
                            :
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className='catalog'>
                                {/* <p className='header-title'>СОЮЗ 99</p> */}
                                {/* <img src={star} alt='' className='loading__star-bg'></img> */}
                                {/* <motion.div
                        initial={{ opacity: 0.5, y: 0 }}
                        animate={{ opacity: 1, y: '-110vh' }}
                        transition={{ duration: 3.5, repeat: Infinity, repeatDelay: 3.0 }}
                        className='catalog__star_anim' style={{ left: randomNumber + 50, top: randomNumber }}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M9.1315 9.1692L7.67581 15.0001L6.22015 9.1692L0.397461 7.71149L6.22015 6.25375L7.67581 0.422852L9.1315 6.25375L14.9542 7.71149L9.1315 9.1692Z" fill="#555B7D" />
                        </svg>

                        <svg className='star-shleif' xmlns="http://www.w3.org/2000/svg" width="7" height="135" viewBox="0 0 7 135" fill="none">
                            <path d="M0 0H7L3.5 135L0 0Z" fill="url(#paint0_linear_1_28)" />
                            <defs>
                                <linearGradient id="paint0_linear_1_28" x1="3.5" y1="0" x2="3.5" y2="135" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#555B7D" stopOpacity="0.27" />
                                    <stop offset="1" stopColor="#fff" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </motion.div> */}
                                <img src={logo} alt='' className='catalog__logo'></img>
                                <div className='catalog__title-menu__box'>
                                    {CATALOG.map((item, i) => {
                                        return (
                                            <p onClick={() => chooseCategory({ id: item.id })} key={item.title} className='catalog__title-menu'>{item.title}</p>
                                        )
                                    })
                                    }
                                    {/* <button onClick={() => setRulesOpen(true)} key={'rule'} className='catalog__title-menu'>Правила</button> */}
                                </div>
                                {realTableLocal ?
                                    <div className='catalog__block-button__box'>
                                        {CATALOG_BUTTON.map((item, i) => {
                                            return (
                                                <>
                                                    <div onClick={() => {
                                                        if (item.type === 'pay_bill') {
                                                            // console.log({ realTableLocal })
                                                            // window.location.href = TABLES_BILL_LINK_MAP[realTableLocal]
                                                            setPayOpen(true)
                                                            // setBillActive(true)
                                                            setBarActive(false)
                                                            return
                                                        }
                                                        OpenConfirm(item)
                                                        setBillActive(false)
                                                        setBarActive(true)
                                                    }} key={i} className='catalog__block-button'>
                                                        <img alt='' src={item.icon}></img>
                                                        <p className='catalog__block-button__title'>{item.title}</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                    : null}
                                {/* <div className='loading__line__box'>
                    <img src={line} alt='' className='catalog__line'></img>
                    <div className='loading__blue'></div>
                    <div className='loading__terms'>
                        <p>Лаунж-бар СОЮЗ 99. © Все права защищены.</p>
                        <p>2024</p>
                    </div>
                    </div> */}
                                <div className='loading__line__box'>
                                    {/* <img src={line} alt='' className='loading__line'></img> */}
                                    {/* <div className='loading__blue'></div> */}
                                    <div className='loading__terms'>
                                        <p>Дизайн и Разработка - <a className='loading__terms-promo-link' href='https://cabahub.com/' target='_blank' rel='noreferrer'>Caba Hub</a> <br />CabaFoodService © Все права защищены. 2024г</p>
                                    </div>
                                </div>
                            </motion.div>
            }
        </>
    );
}

export default Catalog