import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import Rhomb from '../../Rhomb/Rhomb';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import RingWithShadow from '../../RingWithShadow/RingWithShadow';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Tablet.css';
import slideImg from '../../../assets/images/store/slide2.webp';
import { useEffect, useState } from 'react';

const slideText = {
  title: 'iPad mini',
  description: 'Планшет с предустановленным<br />приложением для персонала.',
  price: '64.000',
};

export default function Tablet({ activeIndex, slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 15) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper unicClassName={'bg'}>
      <SlideContent slideTo={slideTo} currentSlideBtn={slideText.title} text={slideText} isStore>
        <img className="tablet__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="tablet__half-circle-wrapper">
          <HalfCircle
            color="var(--color-block-primary)"
            shadowColor="var(--color-block-shadow)"
            rotateAngle="116.996deg"
          />
        </div>

        <div className="tablet__ring-wrapper">
          <RingWithShadow
            color="var(--color-block-primary)"
            innerColor="var(--color-block-active)"
            shadowColor="var(--color-block-shadow)"
          />
        </div>

        <div className="tablet__rhomb-wrapper">
          <Rhomb
            color="var(--color-block-primary)"
            shadowColor="var(--color-block-shadow)"
          />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="tablet__hole-rhomb-wrapper">
          <RhombWithHole
            color="var(--color-block-primary)"
            shadowColor="var(--color-block-shadow)"
            rhombStrokeWidth="19.74%"
            innerColor="var(--color-block-active)"
          />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="tablet__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-block-secondary)" />
          </ElementFlicker>
        </div>

        <div className="tablet__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-block-secondary)"
              rotateAngle="138.541deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
