import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Design.css';
import slideImg from '../../../assets/images/personalization/slide1.webp';
import slideIcon from '../../../assets/icons/personalization/slide1.png';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Индивидуальный дизайн:',
    text: 'Настройка интерфейса меню и приложений в&nbsp;соответствии с фирменным стилем вашего&nbsp;заведения.',
  },
  {
    title: 'Фирменные цвета и материалы:',
    text: 'Использование корпоративных цветов и&nbsp;стиля&nbsp;во&nbsp;всех цифровых и печатных материалах, включая QR-коды.',
  },
];

export default function Design({ activeIndex,slideTo }) {
  const { width } = useWindowSize();
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 7) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText}>
        <img className="design__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="design__rhomb-wrapper">
          <RhombWithHole
            color="var(--color-accent-quindecenary)"
            rhombStrokeWidth="25.55%"
          />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="design__half-circle-wrapper">
          <HalfCircle
            color="var(--color-accent-sexdenary)"
            rotateAngle={width > 900 ? '45deg' : '60deg'}
          />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="design__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-tertiary)" />
          </ElementFlicker>
        </div>

        <div className="design__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-octodenary)"
              rotateAngle="-45deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
