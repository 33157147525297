import telegramIcon from '../icons/telegram-icon.png';
import whatsappIcon from '../icons/whatsapp-icon.png';
import ophisiant from '../images/ophisiant.svg'
import kalian from '../images/kalian-logo.svg'
import beelt from '../images/beelt-loggo.svg'

export const MAIN_API_URL =
  'https://tg-function.yc-cabatest.ru/CABA_NOTIFIER/CABA_FS_LANDING/';

export const ANIMATION_DURATION = 2.5;
export const ANIMATION_DELAY = 0.5;
export const SLIDE_TRANSFORM_DURATION = 0.8;
export const SLIDE_OPACITY_DURATION = 0.5;

export const TARIFF_ID = 'tariff';
export const ERROR_POPUP_ID = 'error-popup';
export const SUCCESS_POPUP_ID = 'success-popup';

export const TARIFF_BASIC = 'basic';
export const TARIFF_PERSONAL = 'personal';

export const DIGITAL_MENU_TAG = 'Цифровое меню';
export const OPTIONS_TAG = 'Опции';
export const PERSONALIZATION_TAG = 'Персонализация';
export const STAFF_TAG = 'Для персонала';
export const PARTNERS_TAG = 'Партнёры';
export const DEMO_TAG = 'Демо';

export const STORAGE_KEY = "clicked_btn"
export const TNG_MENU_LINK = 'https://menu.tng-typography.ru';
export const TNG_SITE_LINK = 'https://tangiers-moscow.ru/lounge/typography';

export const UNION_MENU_LINK = 'https://menu.union99.ru/';
export const UNION_SITE_LINK = 'https://union99.ru/';

export const FOOTER_CONTACT_PHONE = {
  label: '+7 993 263-52-42',
  href: 'tel:+79932635242',
};

export const FOOTER_CONTACT_EMAIL = {
  label: 'info@cabafs.ru',
  href: 'mailto:info@cabafs.ru',
};

export const TELEGRAM_LINK = 'https://t.me/cabafoodservice';
export const WHATSAPP_LINK = '#';

export const SOCIAL_LIST = [
  {
    link: TELEGRAM_LINK,
    icon: telegramIcon,
  },
  // {
  //   link: WHATSAPP_LINK,
  //   icon: whatsappIcon,
  // },
];

export const MAIN_URL = 'https://menu-union-99-strapi.polza-local.ru/api'
export const PHOTO_URL = 'https://menu-union-99-strapi.polza-local.ru/uploads'
export const MENU_URL = 'https://staff.union99.ru/api'

export const CATALOG = [
  {
    id: 1,
    title: 'Чайная карта',
    translit_name: 'kalian'
  },
  // {
  //   id: 2,
  //   title: 'Холодные напитки',
  //   translit_name: 'holnapitok'
  // },
  {
    id: 3,
    title: 'Горячие напитки',
    translit_name: 'gornapitok'
  },
  // {
  //   id: 4,
  //   title: 'Алкоголь',
  //   translit_name: 'alko'
  // },
  // {
  //   id: 5,
  //   title: 'Закуски',
  //   translit_name: 'zakuski'
  // },
]

export const WAITER_CATALOG_BUTTON = {
  title: 'Бармен',
  icon: ophisiant,
  type: 'waiter'
};
// export const MASTER_CATALOG_BUTTON = {
//   title: 'Кальянный мастер',
//   icon: kalian,
//   type: 'master'
// };
export const BILL_CATALOG_BUTTON = {
  title: 'Оплатить счет',
  icon: beelt,
  type: 'pay_bill'
};
export const CATALOG_BUTTON = [
  WAITER_CATALOG_BUTTON,
  // MASTER_CATALOG_BUTTON, 
  BILL_CATALOG_BUTTON];

export const HOOKAH_REQUEST_TYPE = 'hookah';
export const BARMAN_REQUEST_TYPE = 'barman';
export const CHECK_REQUEST_TYPE = 'check';

export const CATALOG_MAIN_LINK = 'catalog'
export const CATALOG_ITEM_LINK = 'item'
export const CART_MAIN_LINK = 'cart'

export const CURRENCY_MAP = {
  'USD': '$',  // United States Dollar
  'EUR': '€',  // Euro
  'JPY': '¥',  // Japanese Yen
  'GBP': '£',  // British Pound
  'AUD': 'A$', // Australian Dollar
  'CAD': 'C$', // Canadian Dollar
  'CHF': 'CHF', // Swiss Franc
  'CNY': '¥',  // Chinese Yuan
  'SEK': 'kr',  // Swedish Krona
  'NZD': 'NZ$', // New Zealand Dollar
  'MXN': '$',  // Mexican Peso
  'SGD': 'S$', // Singapore Dollar
  'HKD': 'HK$', // Hong Kong Dollar
  'NOK': 'kr',  // Norwegian Krone
  'KRW': '₩',  // South Korean Won
  'TRY': '₺',  // Turkish Lira
  'INR': '₹',  // Indian Rupee
  'RUB': '₽',  // Russian Ruble
  'BRL': 'R$', // Brazilian Real
  'ZAR': 'R',  // South African Rand
};

export const productIdStorageKey = 'products_id'
export const historyIdStorageKey = 'history_cart'
export const tableStorageKey = 'table'

export const TABLES_BILL_LINK_MAP = {
  '1': 'https://eda.yandex.ru/inplace/menu?uuid=95b61255-5cc1-48fa-b8b6-270eec59f30d',
  '2': 'https://eda.yandex.ru/inplace/menu?uuid=8c990860-3d77-4592-ae60-468efaf69457',
  '3': 'https://eda.yandex.ru/inplace/menu?uuid=e13433a9-f83d-4bcd-a8d4-b646e1c1b75a',
  '4': 'https://eda.yandex.ru/inplace/menu?uuid=0dd9f4d1-986c-438a-8225-d1dacb77d2f0',
  '5': 'https://eda.yandex.ru/inplace/menu?uuid=bdd72158-b47d-42f1-9c66-9021385f7c96',
  '6': 'https://eda.yandex.ru/inplace/menu?uuid=f8f60a5b-98d6-4090-be86-95833de5927c',
  '7': 'https://eda.yandex.ru/inplace/menu?uuid=abd28392-3eeb-416c-af93-eafd00bc3c81',
  '8': 'https://eda.yandex.ru/inplace/menu?uuid=fba31503-e9cf-4dc6-9980-d3a56aa28163',
  '9': 'https://eda.yandex.ru/inplace/menu?uuid=4b561c32-d3ac-46cc-933b-c651bf9e31b2',
  '10': 'https://eda.yandex.ru/inplace/menu?uuid=f6bec43e-fdc2-4c67-be15-a4670dcbd6cf',
  '11': 'https://eda.yandex.ru/inplace/menu?uuid=3a31aadd-1b95-4479-b833-245b17e19b43',
  '12': 'https://eda.yandex.ru/inplace/menu?uuid=789835e7-68b4-4d46-ba7d-0b53c16a1dc7',
}

export const PRODUCTS = {
  categories: [
    {
      sort: 1,
      id: 15,
      title: "Домашний чай",
      "createdAt": "2024-06-11T23:04:22.741Z",
      "updatedAt": "2024-06-11T23:05:37.874Z",
      "icon": null
    },
    {
      sort: 15,
      id: 16,
      title: "Белый чай",
      "createdAt": "2024-06-11T23:15:41.381Z",
      "updatedAt": "2024-06-11T23:15:56.390Z",
      "icon": null
    },
    {
      sort: 16,
      id: 17,
      title: "Северо-Фуцзянские Улуны",
      "createdAt": "2024-06-11T23:25:55.256Z",
      "updatedAt": "2024-06-11T23:26:33.502Z",
      "icon": null
    },
    {
      sort: 17,
      id: 18,
      title: "Чайные напитки на сифоне",
      "createdAt": "2024-06-11T23:36:17.802Z",
      "updatedAt": "2024-06-11T23:49:35.594Z",
      "icon": null
    },
    {
      sort: 18,
      id: 19,
      title: "Зелёный чай",
      "createdAt": "2024-06-11T23:56:39.141Z",
      "updatedAt": "2024-06-11T23:56:39.141Z",
      "icon": null
    },
    {
      sort: 19,
      id: 20,
      title: "Южно-Фуцзяньские Улуны",
      "createdAt": "2024-06-11T23:59:55.557Z",
      "updatedAt": "2024-06-12T00:00:07.333Z",
      "icon": null
    },
    {
      sort: 20,
      id: 21,
      title: "Гуандунские Улуны",
      "createdAt": "2024-06-12T00:06:06.558Z",
      "updatedAt": "2024-06-12T00:06:06.558Z",
      "icon": null
    },
    {
      sort: 21,
      id: 22,
      title: "Тайваньские Улуны",
      "createdAt": "2024-06-12T00:09:45.440Z",
      "updatedAt": "2024-06-12T00:09:45.440Z",
      "icon": null
    },
    {
      sort: 22,
      id: 23,
      title: "Красные чаи",
      "createdAt": "2024-06-12T00:16:29.458Z",
      "updatedAt": "2024-06-12T00:16:29.458Z",
      "icon": null
    },
    {
      sort: 23,
      id: 24,
      title: "Шу Пуэр",
      "createdAt": "2024-06-12T00:27:24.749Z",
      "updatedAt": "2024-06-12T00:27:24.749Z",
      "icon": null
    },
    {
      sort: 24,
      id: 25,
      title: "Шен Пуэр",
      "createdAt": "2024-06-12T00:29:40.281Z",
      "updatedAt": "2024-06-12T00:29:40.281Z",
      "icon": null
    },
    {
      sort: 25,
      id: 26,
      title: "Тизаны",
      "createdAt": "2024-06-12T00:31:34.686Z",
      "updatedAt": "2024-06-12T00:31:34.686Z",
      "icon": null
    }
  ],
  "items": [
    {
      id: 68,
      title: "Инь Цзюнь Мэй",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:20:57.069Z",
      "updatedAt": "2024-06-12T00:21:07.320Z",
      "publishedAt": "2024-06-12T00:21:07.315Z",
      category: {
        id: 23,
        title: "Красные чаи",
        sort: 22,
        "createdAt": "2024-06-12T00:16:29.458Z",
        "updatedAt": "2024-06-12T00:16:29.458Z"
      },
      prices: [
        {
          id: 84,
          "measure": null,
          "price": 700
        }
      ]
    },
    {
      id: 46,
      title: "Малина-Имбирь-Травы",
      description: null,
      sort: 5,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:12:32.425Z",
      "updatedAt": "2024-06-11T23:12:43.437Z",
      "publishedAt": "2024-06-11T23:12:43.431Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 62,
          "measure": "600мл",
          "price": 850
        }
      ]
    },
    {
      id: 71,
      title: "Цзинь Цзюнь Мэй",
      description: null,
      sort: 4,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:24:31.797Z",
      "updatedAt": "2024-06-12T00:24:34.560Z",
      "publishedAt": "2024-06-12T00:24:34.556Z",
      category: {
        id: 23,
        title: "Красные чаи",
        sort: 22,
        "createdAt": "2024-06-12T00:16:29.458Z",
        "updatedAt": "2024-06-12T00:16:29.458Z"
      },
      prices: [
        {
          id: 87,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 67,
      title: "GABA Медовая",
      description: null,
      sort: 4,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:14:17.289Z",
      "updatedAt": "2024-06-12T00:14:57.707Z",
      "publishedAt": "2024-06-12T00:14:57.703Z",
      category: {
        id: 22,
        title: "Тайваньские Улуны",
        sort: 21,
        "createdAt": "2024-06-12T00:09:45.440Z",
        "updatedAt": "2024-06-12T00:09:45.440Z"
      },
      prices: [
        {
          id: 83,
          "measure": null,
          "price": 1300
        }
      ]
    },
    {
      id: 69,
      title: "Сяо Чжун",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:22:12.543Z",
      "updatedAt": "2024-06-12T00:22:20.622Z",
      "publishedAt": "2024-06-12T00:22:20.616Z",
      category: {
        id: 23,
        title: "Красные чаи",
        sort: 22,
        "createdAt": "2024-06-12T00:16:29.458Z",
        "updatedAt": "2024-06-12T00:16:29.458Z"
      },
      prices: [
        {
          id: 85,
          "measure": null,
          "price": 800
        }
      ]
    },
    {
      id: 52,
      title: "Ци Лань",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:29:25.860Z",
      "updatedAt": "2024-06-11T23:29:29.945Z",
      "publishedAt": "2024-06-11T23:29:29.941Z",
      category: {
        id: 17,
        title: "Северо-Фуцзянские Улуны",
        sort: 16,
        "createdAt": "2024-06-11T23:25:55.256Z",
        "updatedAt": "2024-06-11T23:26:33.502Z"
      },
      prices: [
        {
          id: 68,
          "measure": null,
          "price": 1100
        }
      ]
    },
    {
      id: 74,
      title: "Е Шен",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:30:17.186Z",
      "updatedAt": "2024-06-12T00:30:51.111Z",
      "publishedAt": "2024-06-12T00:30:51.105Z",
      category: {
        id: 25,
        title: "Шен Пуэр",
        sort: 24,
        "createdAt": "2024-06-12T00:29:40.281Z",
        "updatedAt": "2024-06-12T00:29:40.281Z"
      },
      prices: [
        {
          id: 90,
          "measure": null,
          "price": 1100
        }
      ]
    },
    {
      id: 53,
      title: "Янь Гу Хуа Сян",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:30:25.838Z",
      "updatedAt": "2024-06-11T23:30:33.708Z",
      "publishedAt": "2024-06-11T23:30:33.703Z",
      category: {
        id: 17,
        title: "Северо-Фуцзянские Улуны",
        sort: 16,
        "createdAt": "2024-06-11T23:25:55.256Z",
        "updatedAt": "2024-06-11T23:26:33.502Z"
      },
      prices: [
        {
          id: 69,
          "measure": null,
          "price": 1100
        }
      ]
    },
    {
      id: 76,
      title: "Травяной",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:40:55.287Z",
      "updatedAt": "2024-06-12T00:41:11.310Z",
      "publishedAt": "2024-06-12T00:41:11.304Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 92,
          "measure": null,
          "price": 750
        }
      ]
    },
    {
      id: 47,
      title: "Авторский Домашний Чай",
      description: null,
      sort: 6,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:13:56.198Z",
      "updatedAt": "2024-06-11T23:14:33.401Z",
      "publishedAt": "2024-06-11T23:14:33.396Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 63,
          "measure": "600мл",
          "price": 1200
        }
      ]
    },
    {
      id: 75,
      title: "Цветочный",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:36:10.334Z",
      "updatedAt": "2024-06-12T00:39:43.963Z",
      "publishedAt": "2024-06-12T00:39:43.957Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 91,
          "measure": null,
          "price": 750
        }
      ]
    },
    {
      id: 58,
      title: "Моли Хуа Ча",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:58:25.519Z",
      "updatedAt": "2024-06-11T23:58:27.516Z",
      "publishedAt": "2024-06-11T23:58:27.512Z",
      category: {
        id: 19,
        title: "Зелёный чай",
        sort: 18,
        "createdAt": "2024-06-11T23:56:39.141Z",
        "updatedAt": "2024-06-11T23:56:39.141Z"
      },
      prices: [
        {
          id: 74,
          "measure": null,
          "price": 800
        }
      ]
    },
    {
      id: 72,
      title: "Ча Тоу",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:28:12.457Z",
      "updatedAt": "2024-06-12T00:28:15.776Z",
      "publishedAt": "2024-06-12T00:28:15.772Z",
      category: {
        id: 24,
        title: "Шу Пуэр",
        sort: 23,
        "createdAt": "2024-06-12T00:27:24.749Z",
        "updatedAt": "2024-06-12T00:27:24.749Z"
      },
      prices: [
        {
          id: 88,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 44,
      title: "Черника-Лайм-Персик",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:09:20.137Z",
      "updatedAt": "2024-06-11T23:09:27.991Z",
      "publishedAt": "2024-06-11T23:09:27.985Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 60,
          "measure": "600мл",
          "price": 850
        }
      ]
    },
    {
      id: 77,
      title: "Гибискус",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:41:48.862Z",
      "updatedAt": "2024-06-12T00:41:50.443Z",
      "publishedAt": "2024-06-12T00:41:50.439Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 93,
          "measure": null,
          "price": 700
        }
      ]
    },
    {
      id: 60,
      title: "Женьшень Улун",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:02:31.047Z",
      "updatedAt": "2024-06-12T00:02:33.690Z",
      "publishedAt": "2024-06-12T00:02:33.681Z",
      category: {
        id: 20,
        title: "Южно-Фуцзяньские Улуны",
        sort: 19,
        "createdAt": "2024-06-11T23:59:55.557Z",
        "updatedAt": "2024-06-12T00:00:07.333Z"
      },
      prices: [
        {
          id: 76,
          "measure": null,
          "price": 800
        }
      ]
    },
    {
      id: 56,
      title: "Сифон Premium",
      description: "Премиальные сорта китайского чая провариваются на сифоне с использованием смолы Пуэра или GABA на выбор",
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:54:30.481Z",
      "updatedAt": "2024-06-11T23:54:50.610Z",
      "publishedAt": "2024-06-11T23:54:50.605Z",
      category: {
        id: 18,
        title: "Чайные напитки на сифоне",
        sort: 17,
        "createdAt": "2024-06-11T23:36:17.802Z",
        "updatedAt": "2024-06-11T23:49:35.594Z"
      },
      prices: [
        {
          id: 72,
          "measure": "600мл",
          "price": 2200
        }
      ]
    },
    {
      id: 48,
      title: "Я Бао",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:20:48.111Z",
      "updatedAt": "2024-06-11T23:20:49.933Z",
      "publishedAt": "2024-06-11T23:20:49.929Z",
      category: {
        id: 16,
        title: "Белый чай",
        sort: 15,
        "createdAt": "2024-06-11T23:15:41.381Z",
        "updatedAt": "2024-06-11T23:15:56.390Z"
      },
      prices: [
        {
          id: 64,
          "measure": null,
          "price": 1500
        }
      ]
    },
    {
      id: 59,
      title: "Те Гуань Инь",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:01:47.957Z",
      "updatedAt": "2024-06-12T00:01:51.268Z",
      "publishedAt": "2024-06-12T00:01:51.252Z",
      category: {
        id: 20,
        title: "Южно-Фуцзяньские Улуны",
        sort: 19,
        "createdAt": "2024-06-11T23:59:55.557Z",
        "updatedAt": "2024-06-12T00:00:07.333Z"
      },
      prices: [
        {
          id: 75,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 42,
      title: "Ежевика-Гранат-Кофе",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:06:47.785Z",
      "updatedAt": "2024-06-11T23:06:49.931Z",
      "publishedAt": "2024-06-11T23:06:49.926Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 58,
          "measure": "600мл",
          "price": 850
        }
      ]
    },
    {
      id: 49,
      title: "Бай Хао Инь Чжень",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:21:27.534Z",
      "updatedAt": "2024-06-11T23:21:30.680Z",
      "publishedAt": "2024-06-11T23:21:30.674Z",
      category: {
        id: 16,
        title: "Белый чай",
        sort: 15,
        "createdAt": "2024-06-11T23:15:41.381Z",
        "updatedAt": "2024-06-11T23:15:56.390Z"
      },
      prices: [
        {
          id: 65,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 63,
      title: "Фэн Хуан Дань Цун Сюэ Пянь",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:08:37.710Z",
      "updatedAt": "2024-06-12T00:08:39.833Z",
      "publishedAt": "2024-06-12T00:08:39.827Z",
      category: {
        id: 21,
        title: "Гуандунские Улуны",
        sort: 20,
        "createdAt": "2024-06-12T00:06:06.558Z",
        "updatedAt": "2024-06-12T00:06:06.558Z"
      },
      prices: [
        {
          id: 79,
          "measure": null,
          "price": 1100
        }
      ]
    },
    {
      id: 43,
      title: "Клюква - Саган-Дайля",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:07:43.747Z",
      "updatedAt": "2024-06-11T23:08:02.488Z",
      "publishedAt": "2024-06-11T23:08:02.482Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 59,
          "measure": "600мл",
          "price": 850
        }
      ]
    },
    {
      id: 70,
      title: "Дянь Хун",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:23:55.136Z",
      "updatedAt": "2024-06-12T00:23:57.697Z",
      "publishedAt": "2024-06-12T00:23:57.692Z",
      category: {
        id: 23,
        title: "Красные чаи",
        sort: 22,
        "createdAt": "2024-06-12T00:16:29.458Z",
        "updatedAt": "2024-06-12T00:16:29.458Z"
      },
      prices: [
        {
          id: 86,
          "measure": null,
          "price": 800
        }
      ]
    },
    {
      id: 73,
      title: "Гу Шу",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:28:52.941Z",
      "updatedAt": "2024-06-12T00:28:54.924Z",
      "publishedAt": "2024-06-12T00:28:54.900Z",
      category: {
        id: 24,
        title: "Шу Пуэр",
        sort: 23,
        "createdAt": "2024-06-12T00:27:24.749Z",
        "updatedAt": "2024-06-12T00:27:24.749Z"
      },
      prices: [
        {
          id: 89,
          "measure": null,
          "price": 900
        }
      ]
    },
    {
      id: 51,
      title: "Да Хун Пао",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:28:38.419Z",
      "updatedAt": "2024-06-11T23:28:47.275Z",
      "publishedAt": "2024-06-11T23:28:47.269Z",
      category: {
        id: 17,
        title: "Северо-Фуцзянские Улуны",
        sort: 16,
        "createdAt": "2024-06-11T23:25:55.256Z",
        "updatedAt": "2024-06-11T23:26:33.502Z"
      },
      prices: [
        {
          id: 67,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 78,
      title: "Ку Цяо",
      description: null,
      sort: 4,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:42:26.129Z",
      "updatedAt": "2024-06-12T00:42:27.592Z",
      "publishedAt": "2024-06-12T00:42:27.586Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 94,
          "measure": null,
          "price": 750
        }
      ]
    },
    {
      id: 45,
      title: "Облепиха-Розмарин-Корица",
      description: null,
      sort: 4,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:11:14.252Z",
      "updatedAt": "2024-06-11T23:11:18.056Z",
      "publishedAt": "2024-06-11T23:11:18.051Z",
      category: {
        id: 15,
        title: "Домашний чай",
        sort: 1,
        "createdAt": "2024-06-11T23:04:22.741Z",
        "updatedAt": "2024-06-11T23:05:37.874Z"
      },
      prices: [
        {
          id: 61,
          "measure": "600мл",
          "price": 850
        }
      ]
    },
    {
      id: 64,
      title: "GABA Красная",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:12:30.308Z",
      "updatedAt": "2024-06-12T00:12:31.905Z",
      "publishedAt": "2024-06-12T00:12:31.900Z",
      category: {
        id: 22,
        title: "Тайваньские Улуны",
        sort: 21,
        "createdAt": "2024-06-12T00:09:45.440Z",
        "updatedAt": "2024-06-12T00:09:45.440Z"
      },
      prices: [
        {
          id: 80,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 80,
      title: "Саган-Дайля",
      description: null,
      sort: 6,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:43:30.307Z",
      "updatedAt": "2024-06-12T00:43:32.585Z",
      "publishedAt": "2024-06-12T00:43:32.579Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 96,
          "measure": null,
          "price": 800
        }
      ]
    },
    {
      id: 54,
      title: "Чай на сифоне",
      description: "В сифоне можно приготовить любой чайный напиток, но особенно вкусными получаются пуэры, улуны и красные чаи",
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:51:24.465Z",
      "updatedAt": "2024-06-11T23:51:26.052Z",
      "publishedAt": "2024-06-11T23:51:26.046Z",
      category: {
        id: 18,
        title: "Чайные напитки на сифоне",
        sort: 17,
        "createdAt": "2024-06-11T23:36:17.802Z",
        "updatedAt": "2024-06-11T23:49:35.594Z"
      },
      prices: [
        {
          id: 70,
          "measure": null,
          "price": 1300
        }
      ]
    },
    {
      id: 62,
      title: "Фэн Хуан Дань Цун Ми Лан Сян",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:07:29.915Z",
      "updatedAt": "2024-06-12T00:07:38.076Z",
      "publishedAt": "2024-06-12T00:07:38.071Z",
      category: {
        id: 21,
        title: "Гуандунские Улуны",
        sort: 20,
        "createdAt": "2024-06-12T00:06:06.558Z",
        "updatedAt": "2024-06-12T00:06:06.558Z"
      },
      prices: [
        {
          id: 78,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 57,
      title: "Би Ло Чунь",
      description: null,
      sort: 1,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:57:38.424Z",
      "updatedAt": "2024-06-11T23:57:42.876Z",
      "publishedAt": "2024-06-11T23:57:42.871Z",
      category: {
        id: 19,
        title: "Зелёный чай",
        sort: 18,
        "createdAt": "2024-06-11T23:56:39.141Z",
        "updatedAt": "2024-06-11T23:56:39.141Z"
      },
      prices: [
        {
          id: 73,
          "measure": null,
          "price": 900
        }
      ]
    },
    {
      id: 50,
      title: "Лао Бай Ча",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:22:05.190Z",
      "updatedAt": "2024-06-11T23:22:08.508Z",
      "publishedAt": "2024-06-11T23:22:08.503Z",
      category: {
        id: 16,
        title: "Белый чай",
        sort: 15,
        "createdAt": "2024-06-11T23:15:41.381Z",
        "updatedAt": "2024-06-11T23:15:56.390Z"
      },
      prices: [
        {
          id: 66,
          "measure": null,
          "price": 1000
        }
      ]
    },
    {
      id: 55,
      title: "Вишнёвый Шу",
      description: "Шу Пуэр, вишнёвый сок",
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-11T23:52:42.159Z",
      "updatedAt": "2024-06-11T23:52:44.437Z",
      "publishedAt": "2024-06-11T23:52:44.433Z",
      category: {
        id: 18,
        title: "Чайные напитки на сифоне",
        sort: 17,
        "createdAt": "2024-06-11T23:36:17.802Z",
        "updatedAt": "2024-06-11T23:49:35.594Z"
      },
      prices: [
        {
          id: 71,
          "measure": "600",
          "price": 1500
        }
      ]
    },
    {
      id: 65,
      title: "GABA Золотая",
      description: null,
      sort: 2,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:13:02.658Z",
      "updatedAt": "2024-06-12T00:13:04.538Z",
      "publishedAt": "2024-06-12T00:13:04.533Z",
      category: {
        id: 22,
        title: "Тайваньские Улуны",
        sort: 21,
        "createdAt": "2024-06-12T00:09:45.440Z",
        "updatedAt": "2024-06-12T00:09:45.440Z"
      },
      prices: [
        {
          id: 81,
          "measure": null,
          "price": 1500
        }
      ]
    },
    {
      id: 61,
      title: "Най Сян",
      description: null,
      sort: 3,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:03:19.583Z",
      "updatedAt": "2024-06-12T00:03:21.640Z",
      "publishedAt": "2024-06-12T00:03:21.637Z",
      category: {
        id: 20,
        title: "Южно-Фуцзяньские Улуны",
        sort: 19,
        "createdAt": "2024-06-11T23:59:55.557Z",
        "updatedAt": "2024-06-12T00:00:07.333Z"
      },
      prices: [
        {
          id: 77,
          "measure": null,
          "price": 850
        }
      ]
    },
    {
      id: 79,
      title: "Эрл Грей",
      description: null,
      sort: 5,
      "VAT": 0,
      RegistererProductId: null,
      "createdAt": "2024-06-12T00:43:00.093Z",
      "updatedAt": "2024-06-12T00:43:01.739Z",
      "publishedAt": "2024-06-12T00:43:01.721Z",
      category: {
        id: 26,
        title: "Тизаны",
        sort: 25,
        "createdAt": "2024-06-12T00:31:34.686Z",
        "updatedAt": "2024-06-12T00:31:34.686Z"
      },
      prices: [
        {
          id: 95,
          "measure": null,
          "price": 700
        }
      ]
    }
  ]
}
export const BAR_PRODUCTS = {
  "categories": [
    {
      "sort": 1,
      "id": 2,
      "title": "Вода",
      "createdAt": "2024-06-11T17:56:09.016Z",
      "updatedAt": "2024-06-11T17:56:09.016Z",
      "icon": {
        "id": 15,
        "name": "drop-water-renewable 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "drop_water_renewable_1_098fb2d04c",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.7,
        "url": "/uploads/drop_water_renewable_1_098fb2d04c.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.137Z",
        "updatedAt": "2024-06-11T12:08:38.137Z"
      }
    },
    {
      "sort": 2,
      "id": 3,
      "title": "Газированные Напитки",
      "createdAt": "2024-06-11T20:57:30.830Z",
      "updatedAt": "2024-06-11T21:26:00.569Z",
      "icon": {
        "id": 29,
        "name": "milk-glass 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "milk_glass_1_fddbfbf7f3",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.8,
        "url": "/uploads/milk_glass_1_fddbfbf7f3.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.995Z",
        "updatedAt": "2024-06-11T20:58:32.289Z"
      }
    },
    {
      "sort": 3,
      "id": 4,
      "title": "Кофе",
      "createdAt": "2024-06-11T21:05:11.185Z",
      "updatedAt": "2024-06-11T21:46:44.156Z",
      "icon": {
        "id": 10,
        "name": "coffee-cup 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "coffee_cup_1_6779e445c9",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.86,
        "url": "/uploads/coffee_cup_1_6779e445c9.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:37.589Z",
        "updatedAt": "2024-06-11T12:08:37.589Z"
      }
    },
    {
      "sort": 4,
      "id": 5,
      "title": "Фреш",
      "createdAt": "2024-06-11T21:25:27.249Z",
      "updatedAt": "2024-06-11T21:26:18.130Z",
      "icon": {
        "id": 17,
        "name": "Drink 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "Drink_1_50e2779b18",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.83,
        "url": "/uploads/Drink_1_50e2779b18.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.168Z",
        "updatedAt": "2024-06-11T12:08:38.168Z"
      }
    },
    {
      "sort": 5,
      "id": 6,
      "title": "Соки",
      "createdAt": "2024-06-11T21:29:37.170Z",
      "updatedAt": "2024-06-11T21:47:58.299Z",
      "icon": {
        "id": 25,
        "name": "orange 2.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "orange_2_b3af8d9187",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.99,
        "url": "/uploads/orange_2_b3af8d9187.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.977Z",
        "updatedAt": "2024-06-11T12:08:38.977Z"
      }
    },
    {
      "sort": 6,
      "id": 7,
      "title": "Milkshake",
      "createdAt": "2024-06-11T21:31:43.370Z",
      "updatedAt": "2024-06-11T21:48:29.923Z",
      "icon": {
        "id": 29,
        "name": "milk-glass 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "milk_glass_1_fddbfbf7f3",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.8,
        "url": "/uploads/milk_glass_1_fddbfbf7f3.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.995Z",
        "updatedAt": "2024-06-11T20:58:32.289Z"
      }
    },
    {
      "sort": 7,
      "id": 8,
      "title": "Безалкогольные Коктейли",
      "createdAt": "2024-06-11T21:37:30.095Z",
      "updatedAt": "2024-06-11T21:49:23.247Z",
      "icon": {
        "id": 21,
        "name": "jar-honey 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "jar_honey_1_940f3cb20c",
        "ext": ".png",
        "mime": "image/png",
        "size": 1.04,
        "url": "/uploads/jar_honey_1_940f3cb20c.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.520Z",
        "updatedAt": "2024-06-11T12:08:38.520Z"
      }
    },
    {
      "sort": 8,
      "id": 9,
      "title": "Лимонад",
      "createdAt": "2024-06-11T21:46:05.845Z",
      "updatedAt": "2024-06-11T21:49:36.671Z",
      "icon": {
        "id": 19,
        "name": "lemonade 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "lemonade_1_9d5423623c",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.87,
        "url": "/uploads/lemonade_1_9d5423623c.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:38.516Z",
        "updatedAt": "2024-06-11T12:08:38.516Z"
      }
    },
    {
      "sort": 9,
      "id": 10,
      "title": "Пиво",
      "createdAt": "2024-06-11T22:27:59.519Z",
      "updatedAt": "2024-06-11T22:28:15.280Z",
      "icon": {
        "id": 1,
        "name": "Beer 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "Beer_1_5ad6f099d3",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.69,
        "url": "/uploads/Beer_1_5ad6f099d3.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:37.040Z",
        "updatedAt": "2024-06-11T12:08:37.040Z"
      }
    },
    {
      "sort": 10,
      "id": 11,
      "title": "Сидр",
      "createdAt": "2024-06-11T22:44:57.720Z",
      "updatedAt": "2024-06-11T22:44:57.720Z",
      "icon": {
        "id": 6,
        "name": "apple-slice  1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "apple_slice_1_2821109225",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.93,
        "url": "/uploads/apple_slice_1_2821109225.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:37.175Z",
        "updatedAt": "2024-06-11T12:08:37.175Z"
      }
    },
    {
      "sort": 11,
      "id": 12,
      "title": "Бутылочное пиво",
      "createdAt": "2024-06-11T22:47:07.244Z",
      "updatedAt": "2024-06-11T22:47:07.244Z",
      "icon": {
        "id": 11,
        "name": "Beer, Bottle, Glass 1.png",
        "alternativeText": null,
        "caption": null,
        "width": 104,
        "height": 104,
        "formats": null,
        "hash": "Beer_Bottle_Glass_1_a74fb53912",
        "ext": ".png",
        "mime": "image/png",
        "size": 0.88,
        "url": "/uploads/Beer_Bottle_Glass_1_a74fb53912.png",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "createdAt": "2024-06-11T12:08:37.593Z",
        "updatedAt": "2024-06-11T12:08:37.593Z"
      }
    },
    {
      "sort": 12,
      "id": 13,
      "title": "Безалкогольное пиво",
      "createdAt": "2024-06-11T22:54:26.795Z",
      "updatedAt": "2024-06-11T22:54:26.795Z",
      "icon": null
    }
  ],
  "items": [
    {
      "id": 41,
      "title": "Clausthaler Original",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:55:07.584Z",
      "updatedAt": "2024-06-11T22:55:09.528Z",
      "publishedAt": "2024-06-11T22:55:09.520Z",
      "category": {
        "id": 13,
        "title": "Безалкогольное пиво",
        "sort": 12,
        "createdAt": "2024-06-11T22:54:26.795Z",
        "updatedAt": "2024-06-11T22:54:26.795Z"
      },
      "prices": [
        {
          "id": 57,
          "measure": "330мл",
          "price": 400
        }
      ]
    },
    {
      "id": 37,
      "title": "IPA в асс.",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:50:42.528Z",
      "updatedAt": "2024-06-11T22:50:44.251Z",
      "publishedAt": "2024-06-11T22:50:44.245Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 53,
          "measure": "500мл",
          "price": 500
        }
      ]
    },
    {
      "id": 24,
      "title": "Lady Liberty Spritz",
      "desciption": null,
      "sort": 4,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:44:56.617Z",
      "updatedAt": "2024-06-11T21:44:58.609Z",
      "publishedAt": "2024-06-11T21:44:58.605Z",
      "category": {
        "id": 8,
        "title": "Безалкогольные Коктейли",
        "sort": 7,
        "createdAt": "2024-06-11T21:37:30.095Z",
        "updatedAt": "2024-06-11T21:49:23.247Z"
      },
      "prices": [
        {
          "id": 31,
          "measure": "350мл",
          "price": 550
        }
      ]
    },
    {
      "id": 15,
      "title": "Апельсин",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:27:01.295Z",
      "updatedAt": "2024-06-11T21:27:02.659Z",
      "publishedAt": "2024-06-11T21:27:02.654Z",
      "category": {
        "id": 5,
        "title": "Фреш",
        "sort": 4,
        "createdAt": "2024-06-11T21:25:27.249Z",
        "updatedAt": "2024-06-11T21:26:18.130Z"
      },
      "prices": [
        {
          "id": 22,
          "measure": "200мл",
          "price": 400
        }
      ]
    },
    {
      "id": 1,
      "title": "Borjomi газ.",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T18:00:35.667Z",
      "updatedAt": "2024-06-11T20:54:10.818Z",
      "publishedAt": "2024-06-11T18:00:43.973Z",
      "category": {
        "id": 2,
        "title": "Вода",
        "sort": 1,
        "createdAt": "2024-06-11T17:56:09.016Z",
        "updatedAt": "2024-06-11T17:56:09.016Z"
      },
      "prices": [
        {
          "id": 1,
          "measure": "500мл",
          "price": 350
        }
      ]
    },
    {
      "id": 38,
      "title": "Chocolate Stout",
      "desciption": null,
      "sort": 4,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:51:33.091Z",
      "updatedAt": "2024-06-11T22:51:37.148Z",
      "publishedAt": "2024-06-11T22:51:37.142Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 54,
          "measure": "440мл",
          "price": 500
        }
      ]
    },
    {
      "id": 10,
      "title": "Американо",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:16:37.234Z",
      "updatedAt": "2024-06-11T21:20:53.151Z",
      "publishedAt": "2024-06-11T21:20:53.142Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 16,
          "measure": null,
          "price": 300
        }
      ]
    },
    {
      "id": 2,
      "title": "Legend of Baikal ",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T20:53:54.671Z",
      "updatedAt": "2024-06-11T21:20:01.887Z",
      "publishedAt": "2024-06-11T21:20:01.878Z",
      "category": {
        "id": 2,
        "title": "Вода",
        "sort": 1,
        "createdAt": "2024-06-11T17:56:09.016Z",
        "updatedAt": "2024-06-11T17:56:09.016Z"
      },
      "prices": [
        {
          "id": 3,
          "measure": "330мл",
          "price": 270
        },
        {
          "id": 2,
          "measure": "750",
          "price": 520
        }
      ]
    },
    {
      "id": 3,
      "title": "San Pellegrino ",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T20:55:18.912Z",
      "updatedAt": "2024-06-11T21:20:12.557Z",
      "publishedAt": "2024-06-11T21:20:12.553Z",
      "category": {
        "id": 2,
        "title": "Вода",
        "sort": 1,
        "createdAt": "2024-06-11T17:56:09.016Z",
        "updatedAt": "2024-06-11T17:56:09.016Z"
      },
      "prices": [
        {
          "id": 4,
          "measure": "750мл",
          "price": 600
        }
      ]
    },
    {
      "id": 39,
      "title": "Guinness",
      "desciption": null,
      "sort": 5,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:52:53.987Z",
      "updatedAt": "2024-06-11T22:52:56.829Z",
      "publishedAt": "2024-06-11T22:52:56.822Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 55,
          "measure": "440мл",
          "price": 550
        }
      ]
    },
    {
      "id": 14,
      "title": "Раф",
      "desciption": null,
      "sort": 6,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:19:41.768Z",
      "updatedAt": "2024-06-11T21:21:26.539Z",
      "publishedAt": "2024-06-11T21:21:26.534Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 21,
          "measure": null,
          "price": 400
        }
      ]
    },
    {
      "id": 13,
      "title": "Флет Уайт",
      "desciption": null,
      "sort": 5,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:19:09.376Z",
      "updatedAt": "2024-06-11T21:21:19.994Z",
      "publishedAt": "2024-06-11T21:21:19.990Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 20,
          "measure": null,
          "price": 400
        }
      ]
    },
    {
      "id": 11,
      "title": "Капучино",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:17:13.606Z",
      "updatedAt": "2024-06-11T21:21:02.340Z",
      "publishedAt": "2024-06-11T21:21:02.336Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 17,
          "measure": null,
          "price": 350
        }
      ]
    },
    {
      "id": 29,
      "title": "Киви-Малина-Пан",
      "desciption": null,
      "sort": 5,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:13:00.365Z",
      "updatedAt": "2024-06-11T22:13:03.097Z",
      "publishedAt": "2024-06-11T22:13:03.093Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 40,
          "measure": "450мл",
          "price": 450
        },
        {
          "id": 41,
          "measure": "1500мл",
          "price": 1100
        }
      ]
    },
    {
      "id": 27,
      "title": "Крыжовник-Апельсин-Ваниль",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:08:56.800Z",
      "updatedAt": "2024-06-11T22:08:58.393Z",
      "publishedAt": "2024-06-11T22:08:58.384Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 36,
          "measure": "450мл",
          "price": 450
        },
        {
          "id": 37,
          "measure": "1500мл",
          "price": 1100
        }
      ]
    },
    {
      "id": 33,
      "title": "Harp Lager",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:44:01.015Z",
      "updatedAt": "2024-06-11T22:44:04.824Z",
      "publishedAt": "2024-06-11T22:44:04.820Z",
      "category": {
        "id": 10,
        "title": "Пиво",
        "sort": 9,
        "createdAt": "2024-06-11T22:27:59.519Z",
        "updatedAt": "2024-06-11T22:28:15.280Z"
      },
      "prices": [
        {
          "id": 48,
          "measure": "330мл",
          "price": 500
        },
        {
          "id": 49,
          "measure": "500мл",
          "price": 600
        }
      ]
    },
    {
      "id": 31,
      "title": "St.Louis Premium Kriek",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:32:44.498Z",
      "updatedAt": "2024-06-11T22:32:52.783Z",
      "publishedAt": "2024-06-11T22:32:52.771Z",
      "category": {
        "id": 10,
        "title": "Пиво",
        "sort": 9,
        "createdAt": "2024-06-11T22:27:59.519Z",
        "updatedAt": "2024-06-11T22:28:15.280Z"
      },
      "prices": [
        {
          "id": 45,
          "measure": "330мл",
          "price": 420
        },
        {
          "id": 44,
          "measure": "500мл",
          "price": 520
        }
      ]
    },
    {
      "id": 6,
      "title": "Sprite",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:01:02.942Z",
      "updatedAt": "2024-06-11T21:20:32.570Z",
      "publishedAt": "2024-06-11T21:20:32.563Z",
      "category": {
        "id": 3,
        "title": "Газированные Напитки",
        "sort": 2,
        "createdAt": "2024-06-11T20:57:30.830Z",
        "updatedAt": "2024-06-11T21:26:00.569Z"
      },
      "prices": [
        {
          "id": 7,
          "measure": "330мл",
          "price": 350
        }
      ]
    },
    {
      "id": 30,
      "title": "Лимонад by Typography",
      "desciption": null,
      "sort": 6,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:25:43.673Z",
      "updatedAt": "2024-06-11T22:27:14.787Z",
      "publishedAt": "2024-06-11T22:27:14.781Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 42,
          "measure": "450мл",
          "price": 600
        },
        {
          "id": 43,
          "measure": "1500мл",
          "price": 1500
        }
      ]
    },
    {
      "id": 8,
      "title": "Red Bull",
      "desciption": null,
      "sort": 5,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:02:37.326Z",
      "updatedAt": "2024-06-11T21:20:43.260Z",
      "publishedAt": "2024-06-11T21:20:43.255Z",
      "category": {
        "id": 3,
        "title": "Газированные Напитки",
        "sort": 2,
        "createdAt": "2024-06-11T20:57:30.830Z",
        "updatedAt": "2024-06-11T21:26:00.569Z"
      },
      "prices": [
        {
          "id": 9,
          "measure": "250мл",
          "price": 350
        }
      ]
    },
    {
      "id": 7,
      "title": "Тоник Space",
      "desciption": null,
      "sort": 4,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:01:57.713Z",
      "updatedAt": "2024-06-11T21:20:38.224Z",
      "publishedAt": "2024-06-11T21:20:38.216Z",
      "category": {
        "id": 3,
        "title": "Газированные Напитки",
        "sort": 2,
        "createdAt": "2024-06-11T20:57:30.830Z",
        "updatedAt": "2024-06-11T21:26:00.569Z"
      },
      "prices": [
        {
          "id": 8,
          "measure": "330мл",
          "price": 350
        }
      ]
    },
    {
      "id": 34,
      "title": "DaDa",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:45:33.939Z",
      "updatedAt": "2024-06-11T22:45:35.958Z",
      "publishedAt": "2024-06-11T22:45:35.951Z",
      "category": {
        "id": 11,
        "title": "Сидр",
        "sort": 10,
        "createdAt": "2024-06-11T22:44:57.720Z",
        "updatedAt": "2024-06-11T22:44:57.720Z"
      },
      "prices": [
        {
          "id": 50,
          "measure": "500мл",
          "price": 550
        }
      ]
    },
    {
      "id": 23,
      "title": "Leviathan Spritz",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:41:31.204Z",
      "updatedAt": "2024-06-11T21:41:32.746Z",
      "publishedAt": "2024-06-11T21:41:32.741Z",
      "category": {
        "id": 8,
        "title": "Безалкогольные Коктейли",
        "sort": 7,
        "createdAt": "2024-06-11T21:37:30.095Z",
        "updatedAt": "2024-06-11T21:49:23.247Z"
      },
      "prices": [
        {
          "id": 30,
          "measure": "450мл",
          "price": 550
        }
      ]
    },
    {
      "id": 16,
      "title": "Грейпфрут",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:27:43.274Z",
      "updatedAt": "2024-06-11T21:27:45.683Z",
      "publishedAt": "2024-06-11T21:27:45.678Z",
      "category": {
        "id": 5,
        "title": "Фреш",
        "sort": 4,
        "createdAt": "2024-06-11T21:25:27.249Z",
        "updatedAt": "2024-06-11T21:26:18.130Z"
      },
      "prices": [
        {
          "id": 23,
          "measure": "200мл",
          "price": 400
        }
      ]
    },
    {
      "id": 26,
      "title": "Земляника-Юдзу",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:59:18.664Z",
      "updatedAt": "2024-06-11T22:04:03.430Z",
      "publishedAt": "2024-06-11T21:59:55.452Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 33,
          "measure": "450мл",
          "price": 450
        },
        {
          "id": 35,
          "measure": "1500мл",
          "price": 1100
        }
      ]
    },
    {
      "id": 36,
      "title": "Milk of Amnesia IPA",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:49:40.693Z",
      "updatedAt": "2024-06-11T22:49:44.170Z",
      "publishedAt": "2024-06-11T22:49:44.164Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 52,
          "measure": "500мл",
          "price": 450
        }
      ]
    },
    {
      "id": 35,
      "title": "Corona Extra",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:47:55.052Z",
      "updatedAt": "2024-06-11T22:47:56.646Z",
      "publishedAt": "2024-06-11T22:47:56.642Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 51,
          "measure": "350мл",
          "price": 450
        }
      ]
    },
    {
      "id": 17,
      "title": "Гранат",
      "desciption": null,
      "sort": 3,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:28:28.583Z",
      "updatedAt": "2024-06-11T21:28:31.747Z",
      "publishedAt": "2024-06-11T21:28:31.743Z",
      "category": {
        "id": 5,
        "title": "Фреш",
        "sort": 4,
        "createdAt": "2024-06-11T21:25:27.249Z",
        "updatedAt": "2024-06-11T21:26:18.130Z"
      },
      "prices": [
        {
          "id": 24,
          "measure": "200мл",
          "price": 750
        }
      ]
    },
    {
      "id": 28,
      "title": "Грейпфрут-Клубника-Лайм",
      "desciption": null,
      "sort": 4,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:10:52.420Z",
      "updatedAt": "2024-06-11T22:10:55.499Z",
      "publishedAt": "2024-06-11T22:10:55.494Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 39,
          "measure": "450мл",
          "price": 450
        },
        {
          "id": 38,
          "measure": "1500мл",
          "price": 1100
        }
      ]
    },
    {
      "id": 18,
      "title": "Rich в асс.",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:30:24.598Z",
      "updatedAt": "2024-06-11T21:30:25.914Z",
      "publishedAt": "2024-06-11T21:30:25.910Z",
      "category": {
        "id": 6,
        "title": "Соки",
        "sort": 5,
        "createdAt": "2024-06-11T21:29:37.170Z",
        "updatedAt": "2024-06-11T21:47:58.299Z"
      },
      "prices": [
        {
          "id": 25,
          "measure": "200мл",
          "price": 250
        }
      ]
    },
    {
      "id": 25,
      "title": "Манго-Помело-Тибетская ромашка",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:57:39.186Z",
      "updatedAt": "2024-06-11T22:01:54.305Z",
      "publishedAt": "2024-06-11T21:57:42.478Z",
      "category": {
        "id": 9,
        "title": "Лимонад",
        "sort": 8,
        "createdAt": "2024-06-11T21:46:05.845Z",
        "updatedAt": "2024-06-11T21:49:36.671Z"
      },
      "prices": [
        {
          "id": 32,
          "measure": "450мл",
          "price": 450
        },
        {
          "id": 34,
          "measure": "1500мл",
          "price": 1100
        }
      ]
    },
    {
      "id": 19,
      "title": "Oreo",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:32:20.505Z",
      "updatedAt": "2024-06-11T21:32:33.173Z",
      "publishedAt": "2024-06-11T21:32:33.169Z",
      "category": {
        "id": 7,
        "title": "Milkshake",
        "sort": 6,
        "createdAt": "2024-06-11T21:31:43.370Z",
        "updatedAt": "2024-06-11T21:48:29.923Z"
      },
      "prices": [
        {
          "id": 26,
          "measure": "450мл",
          "price": 600
        }
      ]
    },
    {
      "id": 9,
      "title": "Эспрессо",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:13:05.308Z",
      "updatedAt": "2024-06-11T21:20:48.073Z",
      "publishedAt": "2024-06-11T21:20:48.069Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 10,
          "measure": null,
          "price": 270
        }
      ]
    },
    {
      "id": 40,
      "title": "Salden`s WeissBier",
      "desciption": null,
      "sort": 6,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:53:42.723Z",
      "updatedAt": "2024-06-11T22:53:51.707Z",
      "publishedAt": "2024-06-11T22:53:51.702Z",
      "category": {
        "id": 12,
        "title": "Бутылочное пиво",
        "sort": 11,
        "createdAt": "2024-06-11T22:47:07.244Z",
        "updatedAt": "2024-06-11T22:47:07.244Z"
      },
      "prices": [
        {
          "id": 56,
          "measure": "500мл",
          "price": 500
        }
      ]
    },
    {
      "id": 32,
      "title": "Kwak Amber",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T22:42:35.817Z",
      "updatedAt": "2024-06-11T22:42:37.171Z",
      "publishedAt": "2024-06-11T22:42:37.166Z",
      "category": {
        "id": 10,
        "title": "Пиво",
        "sort": 9,
        "createdAt": "2024-06-11T22:27:59.519Z",
        "updatedAt": "2024-06-11T22:28:15.280Z"
      },
      "prices": [
        {
          "id": 46,
          "measure": "330мл",
          "price": 450
        },
        {
          "id": 47,
          "measure": "500мл",
          "price": 550
        }
      ]
    },
    {
      "id": 21,
      "title": "Negroni Sbagliato",
      "desciption": null,
      "sort": 7,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:38:34.379Z",
      "updatedAt": "2024-06-11T21:38:36.186Z",
      "publishedAt": "2024-06-11T21:38:36.178Z",
      "category": {
        "id": 8,
        "title": "Безалкогольные Коктейли",
        "sort": 7,
        "createdAt": "2024-06-11T21:37:30.095Z",
        "updatedAt": "2024-06-11T21:49:23.247Z"
      },
      "prices": [
        {
          "id": 28,
          "measure": "300мл",
          "price": 550
        }
      ]
    },
    {
      "id": 12,
      "title": "Латте",
      "desciption": null,
      "sort": 4,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:17:54.409Z",
      "updatedAt": "2024-06-11T21:21:12.171Z",
      "publishedAt": "2024-06-11T21:21:12.165Z",
      "category": {
        "id": 4,
        "title": "Кофе",
        "sort": 3,
        "createdAt": "2024-06-11T21:05:11.185Z",
        "updatedAt": "2024-06-11T21:46:44.156Z"
      },
      "prices": [
        {
          "id": 18,
          "measure": null,
          "price": 350
        },
        {
          "id": 19,
          "measure": null,
          "price": 350
        }
      ]
    },
    {
      "id": 5,
      "title": "Fanta",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:00:33.438Z",
      "updatedAt": "2024-06-11T21:20:26.325Z",
      "publishedAt": "2024-06-11T21:20:26.317Z",
      "category": {
        "id": 3,
        "title": "Газированные Напитки",
        "sort": 2,
        "createdAt": "2024-06-11T20:57:30.830Z",
        "updatedAt": "2024-06-11T21:26:00.569Z"
      },
      "prices": [
        {
          "id": 6,
          "measure": "330мл",
          "price": 350
        }
      ]
    },
    {
      "id": 22,
      "title": "Moscow Mule",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:40:22.520Z",
      "updatedAt": "2024-06-11T21:40:23.938Z",
      "publishedAt": "2024-06-11T21:40:23.934Z",
      "category": {
        "id": 8,
        "title": "Безалкогольные Коктейли",
        "sort": 7,
        "createdAt": "2024-06-11T21:37:30.095Z",
        "updatedAt": "2024-06-11T21:49:23.247Z"
      },
      "prices": [
        {
          "id": 29,
          "measure": "300мл",
          "price": 550
        }
      ]
    },
    {
      "id": 20,
      "title": "Snickers",
      "desciption": null,
      "sort": 2,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:33:56.964Z",
      "updatedAt": "2024-06-11T21:34:50.735Z",
      "publishedAt": "2024-06-11T21:34:50.717Z",
      "category": {
        "id": 7,
        "title": "Milkshake",
        "sort": 6,
        "createdAt": "2024-06-11T21:31:43.370Z",
        "updatedAt": "2024-06-11T21:48:29.923Z"
      },
      "prices": [
        {
          "id": 27,
          "measure": "450",
          "price": 600
        }
      ]
    },
    {
      "id": 4,
      "title": "Coca-Cola",
      "desciption": null,
      "sort": 1,
      "VAT": 0,
      "RegistererProductId": null,
      "createdAt": "2024-06-11T21:00:00.908Z",
      "updatedAt": "2024-06-11T21:20:20.609Z",
      "publishedAt": "2024-06-11T21:20:20.605Z",
      "category": {
        "id": 3,
        "title": "Газированные Напитки",
        "sort": 2,
        "createdAt": "2024-06-11T20:57:30.830Z",
        "updatedAt": "2024-06-11T21:26:00.569Z"
      },
      "prices": [
        {
          "id": 5,
          "measure": "330мл",
          "price": 350
        }
      ]
    }
  ]
}