import { useContext, useEffect, useState } from 'react';
import './PayPage.css';
import { useNavigate } from 'react-router-dom';
// import mainApi from '../../assets/api/MainApi';
import { PRODUCTS, historyIdStorageKey, productIdStorageKey } from '../../../assets/utils/constants';
import { compareOptions } from '../../../assets/utils/utils';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import moment from 'moment-timezone';
import 'moment/min/locales';
// import CartSecondStep from './CartSecondStep/CartSecondStep';
import { CartContext } from '../../../assets/contexts/cartContext';
import { motion } from 'framer-motion'
import Header from '../Header/Header';

moment.locale('ru');

function PayPage({ setOpenCart, openCart, setAddNew, addNew, isPayOpen, setPayOpen, setBillActive }) {
    const navigate = useNavigate()
    const [productCart, setProductInCart] = useState([])
    const { setCart } = useContext(CartContext);
    const [isPreloaderVisible, setIsPreloaderVisible] = useState(false)
    const [step, setStep] = useState(0);

    function getCart({ productId }) {
        // setIsPreloaderVisible(true);
        // mainApi
        //     .getProductCart({ cartId: productId })
        //     .then((res) => {
        //         console.log(res)
        //         setProductInCart(res)
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         setIsPreloaderVisible(false);
        //     });
    }

    let numbersId = ''
    let cartArray = JSON.parse(localStorage.getItem(historyIdStorageKey)) || [];
    console.log(cartArray[cartArray.length - 1].cart_id)

    let totalAmount = cartArray[cartArray.length - 1].cart_id.reduce(
        function (sum, currentAccount) {
            return sum + (currentAccount.price * currentAccount.count)
        },
        0
    )

    useEffect(() => {
        cartArray.map((item) => {
            for (const [key = 'id', value] of Object.entries(item)) {
                return (numbersId += String(value + ','))
            }
        })
        getCart({ productId: numbersId });
    }, []);

    useEffect(() => {
        totalAmount = cartArray.reduce(
            function (sum, currentAccount) {
                return sum + (currentAccount.price * currentAccount.count)
            },
            0
        )
    }, [cartArray]);

    function handleAddToHistory(e) {
        // setAddNew(!addNew)
        setPayOpen(false)
        setBillActive(true)
        if (totalAmount !== 0) {
            // let cartArrayHistory = JSON.parse(localStorage.getItem(historyIdStorageKey)) || [];
            // const newItem = {
            //     cart: productCart,
            //     cart_id: cartArray,
            //     order_date: moment()
            // };
            // cartArrayHistory.push(newItem);
            // localStorage.setItem(historyIdStorageKey, JSON.stringify(cartArrayHistory));
            localStorage.setItem(productIdStorageKey, JSON.stringify([]));
            // setCart([]);
            // setStep(prevVal => prevVal + 1)
        }
        else {
            e.preventDefault()
        }
    }

    function handleAddToCart(item) {
        // Retrieve the cart from localStorage and parse it; initialize as empty array if not found
        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        // Define a helper function to compare item options
        function isSameItemOptions(cartItem, newItem) {
            if (
                !newItem.options ||
                newItem.options.length === 0 ||
                !cartItem.options ||
                cartItem.options.length === 0
            ) {
                return true; // Treat as same if either item doesn't have options
            }
            const comparisonResult = compareOptions(
                newItem.options,
                cartItem.options
            );
            return comparisonResult.differences.length === 0;
        }

        // Find if the item already exists in the cart with the same options
        const existingCartItemIndex = cartArray.findIndex(
            (cartItem) => cartItem.id === item.id && isSameItemOptions(cartItem, item)
        );
        if (existingCartItemIndex !== -1) {
            // Item with same ID and options found, increase count
            cartArray[existingCartItemIndex].count += 1;
        } else {
            // No matching item found, add new item to cart
            const newItem = {
                id: item.id,
                price: item.price,
                //options: item.options || [], // Ensure options is always an array
                count: 1,
            };
            cartArray.push(newItem);
        }

        // Update the cart in both local state and localStorage
        setCart(cartArray);
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
        //console.log(cartArray)
    }

    function handleRemoveFromCart(itemToRemove, isAll) {
        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        // Define a helper function to compare item options for equality
        function isSameItemOptions(cartItem, item) {
            if (!item.options || item.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
                return true; // Consider items the same if either has no options
            }
            const comparisonResult = compareOptions(item.options, cartItem.options);
            return comparisonResult.differences.length === 0;
        }
        const itemIndex = cartArray.findIndex(cartItem =>
            cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove));

        if (isAll) {
            //Remove all instances of the item with the same options
            cartArray = cartArray.filter(cartItem =>
                !(cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove)));
        } else {
            // Find the index of the item to decrement quantity or remove
            if (itemIndex !== -1) {
                if (cartArray[itemIndex].count > 1) {
                    // Decrement the count
                    cartArray[itemIndex].count -= 1;

                } else {
                    // Remove the item completely if count is 1
                    cartArray.splice(itemIndex, 1);
                    //productCart.splice(itemIndex, 1);
                }
            }
        }
        //Update the cart in both local state and localStorage
        setCart(cartArray);
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className='pay-page'>
            <div className='pay-page__title-box'>
                <p className='pay-page__title'>Ваш заказ</p>
                <svg className='pay-page__close' onClick={() => setPayOpen(false)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M24 8L8 24" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                    <path d="M8 8L24 24" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                </svg>
            </div>
            <div>
                {/* {isPreloaderVisible ?
                    <MiniPreloader />
                    : */}
                {Array.from(PRODUCTS.items).map((item, i) => {
                    return (
                        cartArray[cartArray.length - 1].cart_id.find(city => city.id === item.id) ?
                            <div key={i} className='pay-page__product'>
                                <div>
                                    <p className='pay-page__product__title'>{item.title}</p>
                                    <p className='pay-page__product__price'>
                                        {item.prices.map((item2, i) => (
                                            <div className='pay-page__product__price-and-count'>
                                                {cartArray[cartArray.length - 1].cart_id.find(city => city.id === item.id).count}
                                                <p className='pay-page__product__price__separator'>×</p>
                                                {item2.price}
                                                {/* <span className='pay-page__product__price-ruble'>₽</span> */}
                                            </div>
                                        ))}
                                        <span className='pay-page__product__price-ruble'>₽</span>
                                    </p>
                                </div>
                                <div>
                                    {cartArray.map((itemCartId, i) => {
                                        return (
                                            item.id === itemCartId.id ?
                                                <div key={i}>
                                                    <p onClick={() => handleRemoveFromCart(item, true)} className='pay-page__product__counter-box__delete'>
                                                        удалить
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                            <path d="M13.875 4.875V14.25C13.875 14.6478 13.717 15.0294 13.4357 15.3107C13.1544 15.592 12.7728 15.75 12.375 15.75H5.625C5.22718 15.75 4.84564 15.592 4.56434 15.3107C4.28304 15.0294 4.125 14.6478 4.125 14.25V4.875" stroke="#FE7D20" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.375 4.875V3.375C6.375 3.07663 6.49353 2.79048 6.7045 2.5795C6.91548 2.36853 7.20163 2.25 7.5 2.25H10.5C10.7984 2.25 11.0845 2.36853 11.2955 2.5795C11.5065 2.79048 11.625 3.07663 11.625 3.375V4.5" stroke="#FE7D20" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7.875 11.625L10.125 9.375" stroke="#FE7D20" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M10.125 11.625L7.875 9.375" stroke="#FE7D20" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15 4.875H3" stroke="#FE7D20" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </p>
                                                    <div className='pay-page__product__counter-box'>
                                                        <svg className={`cart__counter-btn ${itemCartId.count === 1 ? 'pay-page__counter-btn_disabled' : ''}`} onClick={() => itemCartId.count === 1 ? null : handleRemoveFromCart(item, false)} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                            <rect width="28" height="28" rx="8" fill="#E9E9ED" />
                                                            <path d="M7.99951 14.0001L19.9995 14.0001" stroke="#555B7D" strokeWidth="2" strokeLinecap="round" />
                                                        </svg>
                                                        <p id={i}>{itemCartId.count}</p>
                                                        <svg onClick={() => handleAddToCart(item)} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                            <rect width="28" height="28" rx="8" fill="#E9E9ED" />
                                                            <path d="M14 8V20" stroke="#555B7D" strokeWidth="2" strokeLinecap="round" />
                                                            <path d="M8 14L20 14" stroke="#555B7D" strokeWidth="2" strokeLinecap="round" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                : null
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            : null
                    )
                })
                }
                {cartArray.length > 0 ?
                    < div className='pay-page__total-box'>
                        <p className='pay-page__total'>Итого:</p>
                        <p className='pay-page__total__number'>
                            {new Intl.NumberFormat("ru-RU").format(totalAmount)}
                            <span className='pay-page__total__number-ruble'>₽</span>
                        </p>
                    </div>
                    :
                    <p className='pay-page__empty'>Выберите товары в меню</p>
                }
                {cartArray.length > 0 ?
                    <button type='button' className={`cart__button-send ${totalAmount <= 0 || isPreloaderVisible ? 'pay-page__button-send_disabled' : ''}`} onClick={handleAddToHistory}>Оплатить</button>
                    : null
                }
            </div>

        </motion.div>
    )
    // : <CartSecondStep setOpenCart={setOpenCart}/>;
}

export default PayPage