import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '../../../assets/icons/icons.js';
import './OrderHistory.css';
import OrderCard from './OrderCard/OrderCard.js';
import { historyIdStorageKey } from '../../../assets/utils/constants.js';
import { motion } from 'framer-motion'

function OrderHistory({ isOpen, setOpen }) {
  const navigate = useNavigate();
  const ordersHistory = JSON.parse(localStorage.getItem(historyIdStorageKey)) || [];
  // console.log(ordersHistory)
  
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="history">
      <div className="history__title-box">
        <p className="history__title">История заказов</p>
        <button
          className="history__close-btn"
          type="button"
          // onClick={() => navigate(-1)}
          onClick={() => setOpen(false)}
        >
          <CloseIcon
            mainClassName="history__close-icon"
            strokeClassName="history__close-icon-stroke"
          />
        </button>
      </div>

      {ordersHistory.length > 0 ? (
        <ul className="history__orders-list">
          {ordersHistory.map((item, i) => (
            <OrderCard order={item} key={i} />
          ))}
        </ul>
      ) : (
        <p className="history__nothing-text">Вы ещё ничего не заказали</p>
      )}
    </motion.section>
  );
}

export default OrderHistory;
