import { useEffect, useState } from 'react';
import './WaiterConf.css';
import { motion } from "framer-motion"
import { useSearchParams } from 'react-router-dom';
// import { BILL_CATALOG_BUTTON, WAITER_CATALOG_BUTTON } from '../../assets/utils/constants';
// import menuApi from '../../assets/api/MenuApi';
// import { parseRequestType } from '../../assets/utils/utils';

const DEFAULT_TABLE = 1;

function WaiterConf({ isOpen, setIsOpen, setAddNew, addNew , setBarActive, setBillActive}) {
    useEffect(() => {
        if (!isOpen.visible) { document.body.style.overflow = 'initial'; }
        else { document.body.style.overflow = 'hidden'; }
    });
    const [openConfirm, setOpenConfirm] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    // console.log(setAddNew)

    function setConfirm() {
        setOpenConfirm(!openConfirm)
        setAddNew(!addNew)
    }

    function setOk() {
        setOpenConfirm(false)
        setIsOpen(false)
        setBarActive(false)
        setBillActive(false)
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        // const botToken = '6783376975:AAEQAEfXw0Q3f8zwfyT7sejT7VEa8-lamdI';
        // const chatId = '-1002112687293';
        // const table = realTableLocal ? realTableLocal : DEFAULT_TABLE;
        // const text =
        //     `${isOpen.type === BILL_CATALOG_BUTTON.type ?
        //         `Получить счет\nСтол: ${table}`
        //         :
        //         `Вызов ${isOpen.type === WAITER_CATALOG_BUTTON.type ? 'бармена' : 'кальянщика'}\nСтол: ${table}`
        //     }`;

        // try {
        //     const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(text)}`);
        //     const data = await response.json();
        //     console.log(data);
        //     sendPublicRequest({ type: parseRequestType(isOpen.type), table: Number(table) });
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };

    function sendPublicRequest({ type, table }) {
        //   menuApi
        //     .sendPublicRequest({ type, table })
        //     .then((res) => {
        //       console.log(res);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
    }

    function closeConfirm() {
        setIsOpen(isOpen => !isOpen)
        setBarActive(false)
        setBillActive(false)
    }

    return (
        <>
            <div onClick={() => setOk()} className={`service-menu__bg ${isOpen.visible ? 'service-menu__bg_open' : ''}`}></div>
            {isOpen.visible ?
                <motion.div
                    animate={{ opacity: 1, y: "0%" }}
                    initial={{ opacity: 0, y: "90%" }}
                    transition={{ ease: "linear" }}
                    className="waiter-conf">
                    <img src={`${isOpen.icon}`} alt=''></img>
                    {isOpen.type === 'waiter' ?
                        <p className='service-menu__title waiter-conf__title'>Позвать бармена</p>
                        : isOpen.type === 'master' ?
                            <p className='service-menu__title waiter-conf__title'>Позвать кальянного мастера</p>
                            : <p className='service-menu__title waiter-conf__title'>Счет</p>
                    }

                    <svg className='service-menu__close' onClick={() => closeConfirm()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>

                    {isOpen.type === 'waiter' ?
                        <p className='waiter-conf__text'>  Вы уверены, что хотите позвать
                            бармена?</p>
                        : isOpen.type === 'master' ?
                            <p className='waiter-conf__text'>  Вы уверены, что хотите позвать
                                кальянного мастера?</p>
                            : <p className='waiter-conf__text'>  Вы уверены, что хотите
                                закрыть счет?</p>
                    }

                    <button onClick={() => setConfirm()} className='waiter-conf__confirm-btn'>
                        {isOpen.type === 'bilt' ? 'Получить счет' : 'Позвать'}
                    </button>
                </motion.div>
                : null}
            {openConfirm ?
                <motion.div
                    animate={{ opacity: 1, y: "0%" }}
                    initial={{ opacity: 0, y: "100%" }}
                    transition={{ type: 'spring', stiffness: 60 }}
                    className="waiter-conf__confirm">
                    <img src={`${isOpen.icon}`} alt=''></img>
                    <svg className='service-menu__close' onClick={() => setOk()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>
                    {isOpen.type === 'waiter' ?
                        <p className='waiter-conf__confirm__text'>Бармен скоро подойдет
                            к вашему столику</p>
                        : isOpen.type === 'master' ?
                            <p className='waiter-conf__confirm__text'>Кальянный мастер скоро подойдет
                                к вашему столику</p>
                            : <p className='waiter-conf__confirm__text'>Бармен скоро подойдет
                                к вашему столику</p>
                    }

                    <button onClick={() => setOk()} className='waiter-conf__confirm__confirm-btn'>
                        OK
                    </button>
                </motion.div>
                : null}
        </>
    );
}

export default WaiterConf;