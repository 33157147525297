import { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import './Header.css'

import { motion } from "framer-motion"
import HeaderSlideMenu from "./HeaderSlideMenu/HeaderSlideMenu";
import logoText from '../../../assets/images/textlogo.svg'

function Header({ setOpenMenu, openMenu, setOpenHistory, isOpenHistory }) {
    const { pathname } = useLocation();
    const [isOpenHeader, setIsOpenHeader] = useState(false);
    const [isHiddenAll, setIsHiddenAll] = useState(true);
    const [isHiddenPart, setIsHiddenPart] = useState(true)

    useEffect(() => {
        // setIsHiddenAll(!openMenu)
        // setIsHiddenPart(pathname === '/catalog')
    }, [pathname])

    // useEffect(() => {
    //     if (!isOpenHeader) { document.body.style.overflow = 'initial'; }
    //     else { document.body.style.overflow = 'hidden'; }
    // }, [isOpenHeader]);
    const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
    const [popupScrollPosition, setPopupScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollWindowPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isOpenHeader) {
            document.body.style.overflow = "hidden";
            document.body.style.top = `-${scrollWindowPosition}px`;
            setPopupScrollPosition(scrollWindowPosition);
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
            console.log(popupScrollPosition);
            window.scrollTo(0, popupScrollPosition);
            setScrollWindowPosition(popupScrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenHeader]);

    const [realTable, setRealTable] = useState(null);
    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    useEffect(() => {
        // let realTableLocal = JSON.parse(localStorage.getItem('table'));
        if (!realTableLocal) {
            // localStorage.removeItem('table');
            setRealTable(null);
        } else {
            setRealTable((realTableLocal));
        }
    }, []);

    function toMain() {
        setOpenMenu(false)
        setIsOpenHeader(false)
        setOpenHistory(false)
    }
    return (
        <>
            <div className={`header ${!openMenu ? 'header_hidden' : ''}`}>
                <div onClick={() => setIsOpenHeader(isOpenHeader => !isOpenHeader)} className={`header__burger-and-rocket-box ${isHiddenPart ? 'header_opacity' : ''}`}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                        <path d="M1.29415 12.305C0.71536 11.9048 0.71536 11.095 1.29415 10.6948C4.01441 8.81362 7.28122 7.79988 10.6351 7.79988L20.0598 7.79988C20.7505 7.79988 21.2601 8.33243 21.2601 8.92844L21.2601 14.0713C21.2601 14.6673 20.7505 15.1999 20.0598 15.1999L10.6351 15.1999C7.28121 15.1999 4.01441 14.1861 1.29415 12.305Z" stroke="#777786" strokeWidth="1.6" />
                        <path d="M11.0601 15.453L12.6804 18.7599C13.3521 20.1308 14.7457 20.9999 16.2723 20.9999L21 20.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M11.0601 6.99988L12.6804 3.693C13.3521 2.32203 14.7457 1.453 16.2723 1.453L21 1.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M18.3335 20.9999L18.3335 15.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M18.3335 1.453L18.3335 6.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M1.29415 12.305C0.71536 11.9048 0.71536 11.095 1.29415 10.6948C4.01441 8.81362 7.28122 7.79988 10.6351 7.79988L20.0598 7.79988C20.7505 7.79988 21.2601 8.33243 21.2601 8.92844L21.2601 14.0713C21.2601 14.6673 20.7505 15.1999 20.0598 15.1999L10.6351 15.1999C7.28121 15.1999 4.01441 14.1861 1.29415 12.305Z" stroke="#777786" strokeWidth="1.6" />
                        <path d="M11.0601 15.453L12.6804 18.7599C13.3521 20.1308 14.7457 20.9999 16.2723 20.9999L21 20.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M11.0601 6.99988L12.6804 3.693C13.3521 2.32203 14.7457 1.453 16.2723 1.453L21 1.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M18.3335 20.9999L18.3335 15.9999" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M18.3335 1.453L18.3335 6.453" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                        <path d="M0.86936 3.64152C0.747323 3.58641 0.747323 3.41311 0.86936 3.35799L2.37719 2.67698C2.41171 2.66139 2.43935 2.63375 2.45493 2.59924L3.13594 1.09141C3.19106 0.96937 3.36436 0.96937 3.41948 1.09141L4.10049 2.59924C4.11607 2.63375 4.14371 2.66139 4.17822 2.67698L5.68606 3.35799C5.8081 3.41311 5.8081 3.58641 5.68606 3.64152L4.17822 4.32253C4.14371 4.33812 4.11607 4.36576 4.10049 4.40027L3.41948 5.90811C3.36436 6.03014 3.19106 6.03014 3.13594 5.90811L2.45493 4.40027C2.43935 4.36576 2.41171 4.33812 2.37719 4.32253L0.86936 3.64152Z" fill="#777786" />
                    </svg> */}
                    <div className="header__burger-box">
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? 45 : -180,
                                y: isOpenHeader ? '3px' : '-3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_first"></motion.div>
                        {isOpenHeader ? '' :
                            <div className="burger-line burger-line_second"></div>}
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? -45 : 180,
                                y: isOpenHeader ? '-3px' : '3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_third"></motion.div>
                    </div>
                </div>

                <Link onClick={() => toMain()} className='header__logo'>
                    {/* <img className='header-title' src={logoText} alt=""></img> */}
                    {/* <p className='header-title'>cabafood</p> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="20" viewBox="0 0 60 20" fill="none">
                        <circle cx="20" cy="10" r="10" fill="#D14005" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 0L20 10L30 20L40 10L30 0Z" fill="#F77225" />
                        <path d="M-9.53674e-07 10C-9.53674e-07 15.5228 4.47715 20 10 20V0C4.47715 0 -9.53674e-07 4.47715 -9.53674e-07 10Z" fill="#CA864D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 15C42.7614 15 45 12.7614 45 10C45 7.23858 42.7614 5 40 5C37.2386 5 35 7.23858 35 10C35 12.7614 37.2386 15 40 15ZM40 20C45.5228 20 50 15.5228 50 10C50 4.47715 45.5228 0 40 0C34.4772 0 30 4.47715 30 10C30 15.5228 34.4772 20 40 20Z" fill="#F7BC70" />
                        <circle cx="40.5556" cy="8.05556" r="0.555556" fill="#A84B4B" />
                        <path d="M60 10C60 15.5228 55.5228 20 50 20V0C55.5228 0 60 4.47715 60 10Z" fill="#FFCB67" />
                    </svg>
                </Link>

                <div
                    // style={{ opacity: 0 }}
                    onClick={() => setOpenHistory(true)}
                >
                    <svg className='header__cosmonavt' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M14.0002 16.1053C15.7442 16.1053 17.1581 14.6915 17.1581 12.9474C17.1581 11.2034 15.7442 9.78955 14.0002 9.78955C12.2561 9.78955 10.8423 11.2034 10.8423 12.9474C10.8423 14.6915 12.2561 16.1053 14.0002 16.1053Z" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.1404 21.8813C19.9142 21.126 19.4508 20.4636 18.8188 19.9921C18.1869 19.5206 17.4199 19.265 16.6315 19.2632H11.3683C10.5799 19.265 9.81294 19.5206 9.181 19.9921C8.54906 20.4636 8.08567 21.126 7.85938 21.8813" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <HeaderSlideMenu setOpenMenu={setOpenMenu} openMenu={openMenu} isOpen={isOpenHeader} setIsOpen={setIsOpenHeader} />
        </>
    )
}
export default Header