import './TariffCardContent.css';
import TarrifType from '../TarrifType/TarrifType';
import { CheckMarkIcon } from '../../../assets/icons/icons';

export default function TariffCardContent({ title, price, subtitle, list }) {
  return (
    <div className="tariff-card-content">
      <TarrifType title={title} price={price} />

      {subtitle && <p className="tariff-card-content__subtitle">{subtitle}</p>}

      <ul
        className={`tariff-card-content__list ${
          subtitle ? 'tariff-card-content__list_type_with-subtitle' : ''
        }`}
      >
        {list.map((item) => (
          <li className="tariff-card-content__item" key={item}>
            <CheckMarkIcon
              mainClassName="tariff-card-content__check-mark"
              fillClassName="tariff-card-content__check-mark-fill"
            />
            <p className="tariff-card-content__item-text">{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}
