import ElementFromOpacity from '../ElementFromOpacity/ElementFromOpacity';
import './Tag.css';

export default function Tag({ text }) {
  return (
    <div className="tag">
      <ElementFromOpacity>
        <div className="tag__wrapper">
          <p className="tag__text">{text}</p>
        </div>
      </ElementFromOpacity>
    </div>
  );
}
