import { useEffect, useState } from 'react';
import { FooterLogo, Logo } from '../../assets/icons/icons';
import {
  FOOTER_CONTACT_EMAIL,
  FOOTER_CONTACT_PHONE,
  SOCIAL_LIST,
} from '../../assets/utils/constants';
import ElementFlicker from '../ElementFlicker/ElementFlicker';
import ElementFromOpacity from '../ElementFromOpacity/ElementFromOpacity';
import ElementsAnimationWrapper from '../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../ElementSlideFromBottom/ElementSlideFromBottom';
import Rhomb from '../Rhomb/Rhomb';
import RhombWithHole from '../RhombWithHole/RhombWithHole';
import Ring from '../Ring/Ring';
import RingWithShadow from '../RingWithShadow/RingWithShadow';
import SnapSection from '../SnapSection/SnapSection';
import './Footer.css';

export default function Footer({ activeIndex }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 17) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SnapSection height="auto">
      <footer className="footer">
        <div className="footer__container">
          <ElementFromOpacity>
            <div className="footer__content">
              <div className="footer__logo-wrapper">
                <Logo
                  mainClassName="footer__logo"
                  fillClassName="footer__logo-fill"
                />
              </div>

              <div className="footer__text-block">
                <div className="footer__contacts-block">
                  <a
                    className="footer__contact-text"
                    href={FOOTER_CONTACT_PHONE.href}
                  >
                    {FOOTER_CONTACT_PHONE.label}
                  </a>
                  <ul className="footer__social-list">
                    {SOCIAL_LIST.map(({ link, icon }, i) => (
                      <li className="footer__social-item" key={i}>
                        <a
                          className="footer__social-link"
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="footer__social-icon"
                            src={icon}
                            alt=""
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="footer__text-logo-wrapper">
                  <FooterLogo
                    mainClassName="footer__text-logo"
                    fillClassName="footer__text-logo-fill"
                  />
                </div>

                <div className="footer__contacts-block">
                  <a
                    className="footer__contact-text"
                    href={FOOTER_CONTACT_EMAIL.href}
                  >
                    {FOOTER_CONTACT_EMAIL.label}
                  </a>
                  <div className="footer__flicker-wrapper">
                    <ElementFlicker elementInView={elementInView}>
                      <Ring
                        color="var(--color-block-secondary)"
                        innerColor="var(--footer-active)"
                        innerSize="35%"
                      />
                    </ElementFlicker>
                  </div>
                </div>
              </div>

              <div className="footer__text-block_type_mobile">
                <div className="footer__text-logo-wrapper">
                  <FooterLogo
                    mainClassName="footer__text-logo"
                    fillClassName="footer__text-logo-fill"
                  />
                </div>

                <ul className="footer__contacts-block">
                  <li className="footer__contact-item">
                    <a
                      className="footer__contact-text"
                      href={FOOTER_CONTACT_PHONE.href}
                    >
                      {FOOTER_CONTACT_PHONE.label}
                    </a>
                  </li>
                  <li className="footer__contact-item">
                    <a
                      className="footer__contact-text"
                      href={FOOTER_CONTACT_EMAIL.href}
                    >
                      {FOOTER_CONTACT_EMAIL.label}
                    </a>
                  </li>
                </ul>

                <ul className="footer__social-list">
                  {SOCIAL_LIST.map(({ link, icon }, i) => (
                    <li className="footer__social-item" key={i}>
                      <a
                        className="footer__social-link"
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="footer__social-icon"
                          src={icon}
                          alt=""
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <p className="footer__copyright">
                Дизайн и Разработка — Caba Hub
                <br />
                CabaFoodService. © Все права защищены. 2024
              </p>
            </div>
          </ElementFromOpacity>
        </div>

        <div className="footer__animation-wrapper">
          <ElementSlideFromBottom elementInView={elementInView}>
            <div className="footer__ring-wrapper">
              <RingWithShadow
                color="var(--footer-primary)"
                innerColor="var(--footer-active)"
                shadowColor="var(--footer-shadow)"
              />
            </div>

            <div className="footer__rhomb-wrapper">
              <RhombWithHole
                color="var(--footer-secondary)"
                shadowColor="var(--footer-shadow)"
                rhombStrokeWidth="23.81%"
                innerColor="var(--footer-active)"
              />
            </div>

            <div className="footer__rhomb-mobile-wrapper">
              <Rhomb
                color="var(--footer-primary)"
                shadowColor="var(--footer-shadow)"
              />
            </div>
          </ElementSlideFromBottom>

          <ElementsAnimationWrapper>
            <div className="footer__flicker-wrapper footer__flicker-wrapper_type_mobile">
              <ElementFlicker elementInView={elementInView}>
                <Ring
                  color="var(--color-block-secondary)"
                  innerColor="var(--footer-active)"
                  innerSize="35%"
                />
              </ElementFlicker>
            </div>
          </ElementsAnimationWrapper>
        </div>
      </footer>
    </SnapSection>
  );
}
