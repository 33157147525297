import { CircleArrowIcon } from '../../assets/icons/icons';
import ElementFromOpacity from '../ElementFromOpacity/ElementFromOpacity';
import './ArrowSlideDown.css';

export default function ArrowSlideDown({ activeIndex }) {
  return (
    <div className="arrow-slide-down">
      <ElementFromOpacity>
        <div className="arrow-slide-down__wrapper">
          <CircleArrowIcon
            mainClassName="arrow-slide-down__icon"
            fillClassName="arrow-slide-down__icon-fill"
          />
        </div>
      </ElementFromOpacity>
    </div>
  );
}
