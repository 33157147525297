import { useRef, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './ElementFlicker.css';
import { ANIMATION_DURATION } from '../../assets/utils/constants';

const animationVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [
      0, 0, 0, 1
    ],
    transition: {
      duration: ANIMATION_DURATION,
      times: [
        0, 0.1, 0.5, 1,
      ],
    },
  },
};

export default function ElementFlicker({ children, elementInView }) {
  const elementRef = useRef(null);
  // const elementInView = useInView(elementRef, { once: true });
  const elementControls = useAnimation();

  useEffect(() => {
    if (elementInView) {
      elementControls.start('visible');
    }
  }, [elementInView, elementControls]);

  return (
    <motion.div
      ref={elementRef}
      variants={animationVariants}
      animate={elementControls}
      initial="hidden"
      className="element-flicker"
    >
      {children}
    </motion.div>
  );
}
