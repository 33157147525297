import './RhombWithHole.css';

export default function RhombWithHole({
  color,
  shadowColor = undefined,
  rhombStrokeWidth,
  innerColor = 'var(--website-background)',
}) {
  return (
    <div
      className="rhomb-with-hole"
      style={{
        '--rhomb-color': color,
        '--size-divider': Math.SQRT2,
        '--shadow-color': shadowColor ?? 'none',
        '--hole-width': rhombStrokeWidth,
        '--inner-color': innerColor,
      }}
    >
      <div className="rhomb-with-hole__rhomb" />
      <div className="rhomb-with-hole__rhomb-inner" />
    </div>
  );
}
