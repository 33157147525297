import { useEffect, useRef, useState } from "react"
import Menu from "../Menu/Menu/Menu"
import SnapSection from "../SnapSection/SnapSection"
import TableApp from "../TableApp/TableApp/TableApp"
import './Demo.css';
import ElementSlideFromBottom from "../ElementSlideFromBottom/ElementSlideFromBottom";
import RhombWithHole from "../RhombWithHole/RhombWithHole";
import ElementSlideFromTop from "../ElementSlideFromTop/ElementSlideFromTop";
import Circle from "../Circle/Circle";
import Ring from "../Ring/Ring";
import HalfCircle from "../HalfCircle/HalfCircle";
import ElementFlicker from "../ElementFlicker/ElementFlicker";
import Tag from "../Tag/Tag";
import { DEMO_TAG } from "../../assets/utils/constants";
import ScreenWrapper from "../ScreenWrapper/ScreenWrapper";
import useWindowSize from "../../assets/hooks/useWindowSize";
import video from '../../assets/video/demo.mp4';
import { useInView } from "framer-motion";

function Demo({ activeIndex, addNew, setAddNew, barActive, setBarActive, menuActive, setMenuActive, billActive, setBillActive }) {
    // const [addNew, setAddNew] = useState(false)
    // const [barActive, setBarActive] = useState(false)
    // const [menuActive, setMenuActive] = useState(false)
    // const [billActive, setBillActive] = useState(false)
    const window = useWindowSize()
    const [elementInView, setElementIsInView] = useState(false)

    useEffect(() => {
        if (activeIndex === 14) {
            setElementIsInView(true)
        }
    }, [activeIndex])

    const videoRef = useRef(null);
    // const isInView = useInView(videoRef, { once: true, margin: "-100px" });
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const video = videoRef.current;
        // setIsUnlocked(false);
        if (activeIndex === 14) {
            setIsPlaying(true)
            video?.play().catch((error) => {
                console.error("Error trying to play the video:", error);
            });
        }
    }, [activeIndex]);

    return (
        <SnapSection>
            <ScreenWrapper>
                {window.width > 1400 ?
                    <>
                        {/* <Tag text={DEMO_TAG} /> */}
                        <div className="demo-page">
                            {/* <Menu
                        barActive={barActive} setBarActive={setBarActive}
                        menuActive={menuActive} setMenuActive={setMenuActive}
                        billActive={billActive} setBillActive={setBillActive}
                        addNew={addNew} setAddNew={setAddNew}
                    /> */}
                            {/* <div></div> */}
                            <svg className="demo-page__connect-svg" xmlns="http://www.w3.org/2000/svg" width="48" height="8" viewBox="0 0 48 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#86B5FA" />
                                <circle cx="24" cy="4" r="4" fill="#86B5FA" />
                                <circle cx="44" cy="4" r="4" fill="#86B5FA" />
                            </svg>
                            {/* <TableApp
                        barActive={barActive} setBarActive={setBarActive}
                        menuActive={menuActive} setMenuActive={setMenuActive}
                        billActive={billActive} setBillActive={setBillActive}
                        addNew={addNew} setAddNew={setAddNew}
                    /> */}
                            <ElementSlideFromBottom elementInView={elementInView}>
                                <div className="demo-page__rhomb">
                                    <RhombWithHole
                                        color="#CCE0FF"
                                        rhombStrokeWidth="16.24%"
                                    />
                                </div>
                            </ElementSlideFromBottom>
                            <ElementSlideFromTop elementInView={elementInView}>
                                <div className="demo-page__ring">
                                    <Ring color="#FDD" />
                                </div>
                            </ElementSlideFromTop>

                            <ElementSlideFromBottom elementInView={elementInView}>
                                <div className="demo-page__half-circle">
                                    <HalfCircle
                                        color="#CEFFFC"
                                        rotateAngle="50deg"
                                    />
                                </div>
                            </ElementSlideFromBottom>
                            <div className="demo-page__circle">
                                <ElementFlicker elementInView={elementInView}>
                                    <Circle color="#FFC672" />
                                </ElementFlicker>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <video
                            key={`demo__video`}
                            className="demo__video"
                            ref={videoRef}
                            src={video}
                            autoPlay={isPlaying}
                            muted
                            playsInline
                            preload="metadata"
                            onError={(e) => console.error("Video error:", e)}
                        >
                            opss!
                        </video>
                        <ElementSlideFromBottom elementInView={elementInView}>
                            <div className="demo-page__rhomb">
                                <RhombWithHole
                                    color="#CCE0FF"
                                    rhombStrokeWidth="16.24%"
                                />
                            </div>
                        </ElementSlideFromBottom>
                        <ElementSlideFromTop elementInView={elementInView}>
                            <div className="demo-page__ring">
                                <Ring color="#FDD" />
                            </div>
                        </ElementSlideFromTop>

                        <ElementSlideFromBottom elementInView={elementInView}>
                            <div className="demo-page__half-circle">
                                <HalfCircle
                                    color="#CEFFFC"
                                    rotateAngle="50deg"
                                />
                            </div>
                        </ElementSlideFromBottom>
                        <div className="demo-page__circle">
                            <ElementFlicker elementInView={elementInView}>
                                <Circle color="#FFC672" />
                            </ElementFlicker>
                        </div>
                    </>
                }
            </ScreenWrapper>
        </SnapSection>
    )
}
export default Demo