import './TarrifType.css';

export default function TarrifType({ title, price }) {
  return (
    <div className="tarrif-type">
      <div className="tarrif-type__title-box">
        <p className="tarrif-type__subtitle">Тариф</p>
        <p className="tarrif-type__title">{title}</p>
      </div>

      {price && (
        <div className="tarrif-type__price-box">
          <p className="tarrif-type__price">{price}</p>
          <p className="tarrif-type__currency">₽</p>
          <div className="tarrif-type__period-box">
            <p className="tarrif-type__period">месяц</p>
          </div>
        </div>
      )}
    </div>
  );
}
