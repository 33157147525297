import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import Rhomb from '../../Rhomb/Rhomb';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Payment.css';
import slideImg from '../../../assets/images/options/slide2.webp';
import slideIcon from '../../../assets/icons/options/slide2.png';
import HalfCircle from '../../HalfCircle/HalfCircle';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Оплата счета из меню:',
    text: 'Возможность оплаты заказа непосредственно из&nbsp;меню с&nbsp;комиссией&nbsp;эквайринга от&nbsp;0,3%.',
  },
  {
    title: 'Чаевые через меню:',
    text: 'Функционал для оставления чаевых&nbsp;при&nbsp;оплате заказа.',
  },
];

export default function Payment({ activeIndex ,slideTo}) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 5) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText} title="Оплата">
        <img className="payment__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="payment__rhomb-wrapper">
          <Rhomb color="var(--color-accent-septendenary)" />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="payment__half-circle-wrapper">
          <Circle color="var(--color-accent-octodenary)" />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="payment__circle-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-novemdenary)" />
          </ElementFlicker>
        </div>

        <div className="payment__ring-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Ring color="var(--color-accent-vigintenary)" innerSize="25%" />
          </ElementFlicker>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
