import { useEffect } from 'react';
import { CATALOG_BUTTON, TABLES_BILL_LINK_MAP } from '../../../assets/utils/constants';
import './ServiceMenu.css';
import { AnimatePresence, motion } from "framer-motion"

function ServiceMenu({ isOpen, setIsOpen, onOpenConf, setPayOpen, setAddNew, addNew, setBillActive, setBarActive }) {

    function OpenConfirm(item) {
        onOpenConf(prevState => ({
            ...prevState,
            visible: true,
            type: item.type,
            icon: item.icon
        }))
    }

    function closeConfirm() {
        setIsOpen(isOpen => !isOpen)
        setBarActive(false)
        setBillActive(false)
    }
    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    return (
        <>
            <div onClick={() => closeConfirm()} className={`service-menu__bg ${isOpen ? 'service-menu__bg_open' : ''}`}></div>
            {isOpen ?
                <motion.div
                    animate={{ opacity: 1, y: "0%", delay: 0.8 }}
                    initial={{ opacity: 0, y: '10%' }}
                    transition={{ ease: "linear" }}
                    className="service-menu">
                    <p className='service-menu__title'>Сервис</p>
                    <svg className='service-menu__close' onClick={() => closeConfirm()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="blue" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>

                    <div className='catalog__block-button__box'>
                        {CATALOG_BUTTON.map((item, i) => {
                            return (
                                <div onClick={() => {
                                    if (item.type === 'pay_bill') {

                                        // window.location.href = TABLES_BILL_LINK_MAP[realTableLocal]
                                        setPayOpen(true)
                                        // setBillActive(true)
                                        setBarActive(false)
                                        return
                                    }
                                    OpenConfirm(item)
                                    setBillActive(false)
                                    setBarActive(true)
                                }} key={i} className='catalog__block-button'>
                                    <img alt='' src={item.icon}></img>
                                    <p className='catalog__block-button__title'>{item.title}</p>
                                </div>
                            )
                        })
                        }
                    </div>
                </motion.div>
                : null}

        </>
    );
}

export default ServiceMenu;