import './NotificationColumn.css';
import { AnimatePresence, motion } from "framer-motion";
import cosmo from '../../../assets/images/cosmo.png';
import SwipeableCard from '../SwipeableCard/SwipeableCard';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { useEffect, useState } from 'react';
// import mainApi from '../../../assets/api/MainApi';
// import useMainWebsocket from '../../../assets/hooks/useMainWebSocket';

function NotificationColumn({ setOpen, setCurId, data, isPreloaderVisible, Submit, preloaderSubmit, isOpen }) {

    function Cur(itemId) {
        setOpen(true)
        setCurId(itemId)
    }

    return (
        <>
            <div id='notification-column' className="notification-column swiper-no-swiping">
                <div className={`notification-column__box`} >
                    {/* <img src={cosmo} alt='' className='notification-column__header__img'></img> */}
                    <div className='notification-column__header'>
                        {/* <img src={cosmo} alt='' className='notification-column__header__img'></img> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                            <path d="M1 10H17" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 16H17" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 4H17" stroke="#434A70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className='notification-column__title'>

                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="20" viewBox="0 0 60 20" fill="none">
                                <circle cx="20" cy="10" r="10" fill="#D14005" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 0L20 10L30 20L40 10L30 0Z" fill="#F77225" />
                                <path d="M-9.53674e-07 10C-9.53674e-07 15.5228 4.47715 20 10 20V0C4.47715 0 -9.53674e-07 4.47715 -9.53674e-07 10Z" fill="#CA864D" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M40 15C42.7614 15 45 12.7614 45 10C45 7.23858 42.7614 5 40 5C37.2386 5 35 7.23858 35 10C35 12.7614 37.2386 15 40 15ZM40 20C45.5228 20 50 15.5228 50 10C50 4.47715 45.5228 0 40 0C34.4772 0 30 4.47715 30 10C30 15.5228 34.4772 20 40 20Z" fill="#F7BC70" />
                                <circle cx="40.5556" cy="8.05556" r="0.555556" fill="#A84B4B" />
                                <path d="M60 10C60 15.5228 55.5228 20 50 20V0C55.5228 0 60 4.47715 60 10Z" fill="#FFCB67" />
                            </svg>
                        </p>
                    </div>
                    {data?.length > 0 ?
                        <>
                            <AnimatePresence >
                                {
                                    isPreloaderVisible ?
                                        <MiniPreloader />
                                        :
                                        data?.map((item, i) => (
                                            <motion.div
                                                layout
                                                initial={{ y: 300 }}
                                                animate={{ y: 0 }}
                                                key={item.id}
                                                exit={{ x: 500 }}
                                                transition={{ duration: 0.1 }}
                                                className={`notification-column__swipeable-card-content`}
                                            >
                                                <SwipeableCard
                                                    item={item}
                                                    Cur={Cur}
                                                />
                                            </motion.div>
                                        ))
                                }
                            </AnimatePresence>
                        </>
                        :
                        <p className='notification-column_empty'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="43" height="90" viewBox="0 0 43 90" fill="none">
                                <path d="M19.3076 2.41134C20.1479 1.19622 21.8524 1.19622 22.6927 2.41134C26.4668 7.86889 28.5003 14.4225 28.5003 21.1504L28.5003 40.0002C28.5003 41.4333 27.3938 42.5008 26.1431 42.5008L15.8572 42.5008C14.6065 42.5008 13.5 41.4333 13.5 40.0002L13.5 21.1504C13.5 14.4225 15.5336 7.86889 19.3076 2.41134Z" stroke="#777786" strokeWidth="3" />
                                <path d="M12.6667 22L4.17514 26.3534C2.83984 27.038 2 28.4123 2 29.9129L2 42.0001" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M30.0004 22.1206L38.8544 26.4588C40.2253 27.1306 41.0944 28.5241 41.0944 30.0508L41.0944 42.0008" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M2 36.668L12.0002 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M41.0944 36.668L31.0942 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M36.7165 1.73872C36.8268 1.49464 37.1734 1.49464 37.2836 1.73872L38.6457 4.75445C38.6768 4.82347 38.7321 4.87875 38.8012 4.90993L41.8169 6.27197C42.061 6.3822 42.061 6.72881 41.8169 6.83905L38.8012 8.20109C38.7321 8.23226 38.6768 8.28755 38.6457 8.35657L37.2836 11.3723C37.1734 11.6164 36.8268 11.6164 36.7165 11.3723L35.3545 8.35657C35.3233 8.28755 35.2681 8.23226 35.199 8.20109L32.1833 6.83905C31.9392 6.72881 31.9392 6.3822 32.1833 6.27197L35.199 4.90993C35.2681 4.87875 35.3233 4.82347 35.3545 4.75445L36.7165 1.73872Z" fill="#FFB31F" />
                                <path d="M22.0011 56.001L22.0011 88.0016" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M10.0012 56.001L10.0012 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M34.001 56.001L34.001 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                            </svg> */}
                            Активных заказов нет
                        </p>
                    }
                </div>
            </div>
        </>
    );
}

export default NotificationColumn;