import { useEffect, useState } from 'react';
import NotificationColumn from '../NotificationColumn/NotificationColumn';
import TableSchema from '../TableSchema/TableSchema';
import './TableApp.css';
import { data } from '../../../assets/utils/constants';
// import mainApi from '../../../assets/api/MainApi';
// import useMainWebsocket from '../../../assets/hooks/useMainWebSocket';
import DeleteConfirmPopup from '../DeleteConfirmPopup/DeleteConfirmPopup';
import moment from 'moment-timezone';
import { animate, motion } from 'framer-motion'
import { useSearchParams } from 'react-router-dom';

function TableApp({ addNew, setAddNew, barActive, setBarActive, billActive, setBillActive }) {
  let final = ''
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker
  //     .register('/sw.js')
  //     .then(event => {
  //       // console.log('Service worker registered', event);
  //     });
  // }

  const [mainWsMessages, setMainWsMessages] = useState([])
  const [task, setTasks] = useState([])
  const [isTasks, setIsTasks] = useState(false)

  // const mainWS = useMainWebsocket({ id: isTasks ? isTasks : null })

  const [isOpen, setOpen] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [curId, setCurId] = useState(null)
  const [preloaderSubmit, setPreloaderSubmit] = useState(false)
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams();
  const catalog = searchParams.get('catalog');

  const data = [
    {
      type: 'bar',
      table: 2,
      id: Math.random().toString(36).substr(2, 9),
      utc_datetime: moment(),
    },
    {
      type: 'bill',
      table: 2,
      id: Math.random().toString(36).substr(2, 9),
      utc_datetime: moment(),
    },
    // {
    //   type: 'bar',
    //   table: 5,
    //   id: Math.random().toString(36).substr(2, 9),
    //   utc_datetime: moment(),
    // },
    {
      type: 'bill',
      table: 4,
      id: Math.random().toString(36).substr(2, 9),
      utc_datetime: moment(),
    },
  ];

  const newTask = {
    type: 'bar',
    table: Math.floor(Math.random() * (12 - 1 + 1) + 1),
    id: moment(),
    utc_datetime: moment(),
  }

  useEffect(() => {
    // if (!mainWS) {
    //   return;
    // }
    // mainWS.onmessage = (e) => {
    //   console.log('Received message app:', e.data);
    //   if (e.data !== 'Missing CSRF Token' && e.data !== 'Missing cookie access_token_cookie' && e.data !== 'Missing cookie refresh_token_cookie' && e.data !== 'Could not find the current user') {
    //     const res = JSON.parse(e.data)
    //     if (res.active) {
    //       setTasks(prevVal => [...prevVal.filter(n => !(n.type === res.type && Number(n.table) === Number(res.table))), res])
    //     }
    //     else {
    //       setTasks(preVal => preVal.filter(item => item.id !== res.id))
    //     }
    //   }
    // }
    // setTasks(preVal => preVal.filter(item => item.id !== newTask.id))
    setTasks(prevVal => [...prevVal.filter(n => !(n.type === newTask.type && Number(n.table) === Number(newTask.table))), newTask])
  }, [addNew]);

  useEffect(() => {
    // console.log(mainWsMessages)
  }, [mainWsMessages])

  useEffect(() => {
    // setIsPreloaderVisible(true)
    // mainApi
    //   .getAllNotification()
    //   .then((res) => {
    //     setIsTasks(true)
    //     setTasks(res.data.reverse());
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     setIsPreloaderVisible(false);
    //   });
    setIsTasks(true)
    setTasks(data);
  }, [])


  function Submit() {
    setSubmit(true)
    setOpen(false)
    setPreloaderSubmit(true);
    // mainApi
    //   .deleteNotification({ deletId: curId })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setPreloaderSubmit(false);
    //   });

    // data.splice(curId, 1)
    console.log(curId)
    setTasks(preVal => preVal.filter(item => item.id !== curId))
    setPreloaderSubmit(false)
  }
  // console.log(data)
  const [notifVisible, setNotifVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    if (billActive) {
      setNotifVisible(true)
      setTimeout(() => {
        setNotifVisible(false)
        setBillActive(false)
      }, 2000)
    }
  }, [billActive])

  useEffect(() => {
    if (barActive) {
      // setBarActive(true)
      setTimeout(() => {
        setBarActive(false)
      }, 2000)
    }
  }, [barActive])

  useEffect(() => {
    if (catalog) {
      setMenuVisible(true)
    } else {
      setMenuVisible(false)
    }
  }, [searchParams])

  const motionTranscription = {
    open: {
      opacity: 1
    },
    closed: {
      opacity: 0
    }
  };

  const notif = {
    open: {
      opacity: 1,
      y: 0,
    },
    closed: {
      opacity: 0,
      y: '-40%'
    }
  };


  return (
    <div className='demo-page__table-app'>

      <motion.div
        initial={false}
        animate={billActive ? "open" : "closed"}
        variants={motionTranscription}
        className='demo-page__motion-transcription'
        style={{ zIndex: billActive ? 3 : 0 }}
      >
        <svg className='demo-page__motion-transcription-svg' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path d="M25.2914 28.3277L24.1895 28.999C24.0355 29.0928 23.8656 29.142 23.6932 29.1426C23.5209 29.1433 23.3508 29.0955 23.1963 29.0029L21.4459 27.9537L19.7308 28.9981C19.5763 29.0922 19.406 29.1413 19.2331 29.1417C19.0603 29.1421 18.8898 29.0937 18.735 29.0003L17 27.9533L15.2649 29.0003C15.1102 29.0937 14.9397 29.1421 14.7669 29.1417C14.594 29.1413 14.4236 29.0922 14.2692 28.9981L12.554 27.9537L10.8037 29.0029C10.6492 29.0955 10.4791 29.1433 10.3067 29.1426C10.1344 29.142 9.96452 29.0928 9.81052 28.999L8.70855 28.3277C8.52418 28.2154 8.36919 28.0432 8.26088 27.8304C8.15258 27.6175 8.09522 27.3723 8.09521 27.1222V6.87739C8.09522 6.62729 8.15257 6.38211 8.26087 6.16923C8.36918 5.95635 8.52416 5.78415 8.70853 5.67185L9.81049 5.00059C9.9645 4.90679 10.1344 4.85761 10.3067 4.85694C10.4791 4.85627 10.6492 4.90412 10.8037 4.99672L12.554 6.04585L14.2692 5.00147C14.4236 4.90743 14.594 4.85827 14.7668 4.85789C14.9397 4.85751 15.1102 4.90592 15.2649 4.99929L17 6.04627L18.735 4.99929C18.8897 4.90592 19.0603 4.85751 19.2331 4.85789C19.4059 4.85827 19.5763 4.90743 19.7308 5.00147L21.4459 6.04585L23.1962 4.99672C23.3507 4.90412 23.5209 4.85627 23.6932 4.85694C23.8656 4.85761 24.0354 4.90679 24.1894 5.00059L25.2914 5.67185C25.4758 5.78414 25.6308 5.95634 25.7391 6.16923C25.8474 6.38211 25.9047 6.62729 25.9047 6.87739V27.1222C25.9047 27.3723 25.8474 27.6175 25.7391 27.8304C25.6308 28.0432 25.4758 28.2154 25.2914 28.3277Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21.0474 12.9521H12.9521" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21.0474 17H12.9521" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M17.8093 21.0474H12.9521" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Оплата счета
      </motion.div>

      <motion.div
        initial={false}
        animate={barActive ? "open" : "closed"}
        variants={motionTranscription}
        className='demo-page__motion-transcription'
        style={{ zIndex: barActive ? 3 : 0 }}>
        <svg className='demo-page__motion-transcription-svg' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path d="M19.9191 3.45589C20.749 4.28576 20.749 5.63123 19.9191 6.4611C19.0892 7.29096 17.7438 7.29096 16.9139 6.4611C16.084 5.63123 16.084 4.28576 16.9139 3.45589C17.7438 2.62603 19.0892 2.62603 19.9191 3.45589Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.96875 31.1667H5.48958C4.80533 31.1667 4.25 30.6113 4.25 29.9271V22.4896C4.25 21.8053 4.80533 21.25 5.48958 21.25H7.96875C8.653 21.25 9.20833 21.8053 9.20833 22.4896V29.9271C9.20833 30.6113 8.653 31.1667 7.96875 31.1667Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16.646 26.2086H19.5374C20.0743 26.2086 20.5957 26.0357 21.0249 25.7127L24.0197 23.4659C24.7677 22.9049 25.8132 22.98 26.4748 23.6402C27.2087 24.374 27.2087 25.5626 26.4748 26.295L23.905 28.8648C23.2137 29.5562 22.3311 30.0293 21.372 30.2206L17.7397 30.9473C17.02 31.0904 16.2762 31.0734 15.5637 30.8949L12.2784 30.0761C11.886 29.9769 11.4822 29.9273 11.0771 29.9273H9.2085" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16.646 26.2083H18.4862C19.5232 26.2083 20.3647 25.3682 20.3647 24.3298V23.9544C20.3647 23.0931 19.7782 22.3422 18.9424 22.134L16.102 21.4243C15.6402 21.3081 15.1656 21.25 14.6896 21.25C13.5407 21.25 12.4158 21.59 11.461 22.2275L9.2085 23.7292" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M29.7502 17H7.0835" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M28.3333 17.0002C28.3333 11.5233 23.8935 7.0835 18.4167 7.0835C12.9398 7.0835 8.5 11.5233 8.5 17.0002" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Вызов официанта
      </motion.div>

      <motion.div
        initial={false}
        animate={menuVisible ? "open" : "closed"}
        variants={motionTranscription}
        className='demo-page__motion-transcription'
        style={{ zIndex: menuVisible ? 3 : 0 }}>
        <svg className='demo-page__motion-transcription-svg' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path d="M24.0833 7.53212C25.3937 7.29554 26.7665 7.15812 28.271 7.10712C29.0757 7.08021 29.75 7.71771 29.75 8.52237V25.2915C29.75 26.0593 29.1366 26.694 28.3702 26.7053C23.9898 26.7719 20.6918 27.5794 17 29.7497C13.3082 27.5794 10.0102 26.7719 5.62983 26.7067C4.86342 26.6954 4.25 26.0593 4.25 25.2915V8.52237C4.25 7.73046 4.90025 7.08021 5.69075 7.10571C10.0413 7.24596 13.3252 8.06479 17 10.1232" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M24.0833 21.587V4.26685C24.0833 3.2341 22.9982 2.51301 22.0759 2.97626C19.5883 4.22293 17.4916 6.75735 17 9.36685V29.7498C17.561 26.7763 20.2031 23.9004 23.1356 22.9158C23.7051 22.7246 24.0833 22.1877 24.0833 21.587Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Просмотр меню
      </motion.div>

      <div className="table-app">
        <motion.div
          animate={notifVisible && billActive ? "open" : "closed"}
          variants={notif}
          initial={false}
          className='table-schema__notification'>
          <svg className='table-schema__notification-svg' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <g clip-path="url(#clip0_476_523)">
              <circle cx="16" cy="16" r="16" fill="white" />
              <path d="M16 0C7.16326 0 0 7.16326 0 16C0 24.8367 7.16326 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16326 24.8367 0 16 0ZM23.6373 9.87733C23.5473 11.3612 20.8213 22.4403 20.8213 22.4403C20.8213 22.4403 20.6578 23.0898 20.0747 23.1111C19.8613 23.1182 19.603 23.0756 19.2948 22.8077C18.6785 22.272 17.2539 21.2504 15.9289 20.3259C15.5283 20.6649 14.7295 21.3641 13.9022 22.1985C12.7313 23.3813 12.8806 22.1985 12.8806 22.1985L13.2409 18.3372L13.2504 18.3419C13.267 18.3016 13.293 18.2708 13.293 18.2708C13.293 18.2708 20.1932 12.0747 20.3757 11.4252C20.3899 11.3754 20.3354 11.3493 20.2643 11.3707C19.8068 11.5413 11.8613 16.6092 10.9843 17.1686C10.9321 17.2018 10.7899 17.1804 10.7899 17.1804L6.9357 15.9123C6.9357 15.9123 6.47585 15.7227 6.62281 15.296C6.65363 15.2083 6.71526 15.1324 6.89778 15.0021C7.75111 14.4 22.7058 8.97185 22.7058 8.97185C22.7058 8.97185 23.1277 8.82726 23.3766 8.92444C23.4904 8.96711 23.5639 9.01689 23.6255 9.19704C23.6468 9.26341 23.661 9.40089 23.6587 9.54074C23.6587 9.6403 23.6444 9.73274 23.6373 9.8797V9.87733Z" fill="#42BAF8" />
            </g>
            <defs>
              <clipPath id="clip0_476_523">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className='table-schema__notification__text '>
            <p className='table-schema__notification__text__bot'>Notifications Bot</p>
            <p className='table-schema__notification__text__text'>Столик 4: прошла полная оплата счета на сумму...</p>
          </div>
        </motion.div>
        <TableSchema data={task} />
        {
          isOpen ?
            <DeleteConfirmPopup
              {...{
                setOpen,
                curId,
                Submit,
                preloaderSubmit,
              }}
            />
            : null
        }
        <NotificationColumn
          data={task}
          Submit={Submit}
          setOpen={setOpen}
          setCurId={setCurId}
          curId={curId}
          isOpen={isOpen}
          preloaderSubmit={preloaderSubmit}
          isPreloaderVisible={isPreloaderVisible}
        />
      </div>
    </div >
  );
}

export default TableApp;
