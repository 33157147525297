import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import Rhomb from '../../Rhomb/Rhomb';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import RingWithShadow from '../../RingWithShadow/RingWithShadow';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Watch.css';
import slideImg from '../../../assets/images/store/slide1.webp';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import Footer from '../../Footer/Footer';
import { useEffect, useState } from 'react';

const slideText = {
  title: 'Watch',
  description: 'Часы с уведомлениями<br />для персонала',
  price: '3.000',
};

export default function Watch({ activeIndex, slideTo }) {
  const { width } = useWindowSize();
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 16) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    // <div>
      <SlideWrapper unicClassName={'bg'}>
        <SlideContent slideTo={slideTo} currentSlideBtn={slideText.title} text={slideText} isStore>
          <img className="watch__slide-image" src={slideImg} alt="" />
        </SlideContent>

        <ElementSlideFromTop elementInView={elementInView}>
          <div className="watch__half-circle-wrapper">
            <HalfCircle
              color="var(--color-block-primary)"
              shadowColor="var(--color-block-shadow)"
              rotateAngle={width > 900 ? '135deg' : '116.996deg'}
            />
          </div>

          <div className="watch__rhomb-wrapper">
            <Rhomb
              color="var(--color-block-primary)"
              shadowColor="var(--color-block-shadow)"
            />
          </div>

          <div className="watch__ring-wrapper">
            <RingWithShadow
              color="var(--color-block-primary)"
              innerColor="var(--color-block-active)"
              shadowColor="var(--color-block-shadow)"
            />
          </div>
        </ElementSlideFromTop>

        <ElementSlideFromBottom elementInView={elementInView}>
          <div className="watch__hole-rhomb-wrapper">
            <RhombWithHole
              color="var(--color-block-primary)"
              shadowColor="var(--color-block-shadow)"
              rhombStrokeWidth="23.81%"
              innerColor="var(--color-block-active)"
            />
          </div>
        </ElementSlideFromBottom>

        <ElementsAnimationWrapper>
          <div className="watch__flicker-wrapper">
            <ElementFlicker elementInView={elementInView}>
              <Circle color="var(--color-block-secondary)" />
            </ElementFlicker>
          </div>

          <div className="watch__rotate-wrapper">
            <ElementRotate elementInView={elementInView}>
              <HalfCircle
                color="var(--color-block-secondary)"
                rotateAngle={width > 900 ? '-126.62deg' : '138.541deg'}
              />
            </ElementRotate>
          </div>
        </ElementsAnimationWrapper>
      </SlideWrapper>
  
  );
}
