import { CloseIcon, ManagerIcon } from '../../assets/icons/icons';
import './Popup.css';

export default function Popup({ text, subtext, isError = false, id }) {
  const popup = document.getElementById(id);

  return (
    <div className="popup">
      <button
        className="popup__close-btn"
        type="button"
        onClick={() => popup.hidePopover()}
      >
        <CloseIcon
          mainClassName="popup__close-icon"
          fillClassName="popup__close-icon-fill"
        />
      </button>

      <ManagerIcon
        mainClassName="popup__icon"
        fillClassName={`popup__icon-fill ${
          isError ? 'popup__icon-fill_type_error' : ''
        }`}
      />
      <p className="popup__text" dangerouslySetInnerHTML={{ __html: text }} />
      {subtext && (
        <p
          className="popup__subtext"
          dangerouslySetInnerHTML={{ __html: subtext }}
        />
      )}
    </div>
  );
}
