import './RingWithShadow.css';

export default function RingWithShadow({
  color = 'var(--color-block-primary)',
  innerSize = '50%',
  innerColor = 'var(--color-block-active)',
  shadowColor = 'var(--color-block-shadow)',
}) {
  return (
    <div
      className="ring-with-shadow"
      style={{
        '--ring-color': color,
        '--inner-size': innerSize,
        '--inner-color': innerColor,
        '--shadow-color': shadowColor,
      }}
    >
      <div className="ring-with-shadow__inner" />
    </div>
  );
}
