import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Updates.css';
import slideImg from '../../../assets/images/staff/slide3.webp';
import slideIcon from '../../../assets/icons/staff/slide3.png';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Моментальное обновление меню:',
    text: 'Изменения в меню становятся доступны клиентам сразу&nbsp;после&nbsp;публикации.',
  },
];

export default function Updates({ activeIndex,slideTo }) {
  const { width } = useWindowSize();
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 11) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText} withScrollBtn>
        <img className="updates__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="updates__rhomb-wrapper">
          <RhombWithHole
            color="var(--color-accent-octonary)"
            rhombStrokeWidth={width > 900 ? '16.77%' : '21%'}
          />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="updates__ring-wrapper">
          <Ring
            color="var(--accent-color-quinary)"
            innerSize={width > 900 ? '40%' : '30%'}
          />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="updates__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-octodenary)"
              rotateAngle="-45deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
