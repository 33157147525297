import './HalfCircle.css';

export default function HalfCircle({
  color,
  shadowColor = undefined,
  rotateAngle = 0,
}) {
  return (
    <div
      className="half-circle"
      style={{
        '--circle-color': color,
        '--shadow-color': shadowColor ?? 'none',
        '--rotate-angle': rotateAngle,
      }}
    />
  );
}
