import ImageElement from './ImageElement/ImageElement';
import './SlideContent.css';
import StoreTextElement from './StoreTextElement/StoreTextElement';
import TextElement from './TextElement/TextElement';

export default function SlideContent({ children, currentSlideBtn, isStore, slideTo, ...props }) {
  return (
    <div className="slide">
      <div className="slide__container">
        <ImageElement>{children}</ImageElement>
        {isStore ? <StoreTextElement slideTo={slideTo} currentSlideBtn={currentSlideBtn} {...props} /> : <TextElement {...props} slideTo={slideTo} />}
      </div>
    </div>
  );
}
