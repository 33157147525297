import './Ring.css';

export default function Ring({
  color,
  innerSize = '50%',
  innerColor = 'var(--website-background)',
}) {
  return (
    <div
      className="ring"
      style={{
        '--ring-color': color,
        '--inner-size': innerSize,
        '--inner-color': innerColor,
      }}
    >
      <div className="ring__inner" />
    </div>
  );
}
