import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper/modules';
import useWindowSize from '../../assets/hooks/useWindowSize';
import {
  TARIFF_BASIC,
  TARIFF_ID,
  TARIFF_PERSONAL,
} from '../../assets/utils/constants';
import ArrowSlideDown from '../ArrowSlideDown/ArrowSlideDown';
import Circle from '../Circle/Circle';
import ElementFlicker from '../ElementFlicker/ElementFlicker';
import ElementRotate from '../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../HalfCircle/HalfCircle';
import RhombWithHole from '../RhombWithHole/RhombWithHole';
import Ring from '../Ring/Ring';
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper';
import SnapSection from '../SnapSection/SnapSection';
import './Tariff.css';
import TariffCard from './TariffCard/TariffCard';
import 'swiper/css';
import 'swiper/css/pagination';
import SuccessPopup from '../Popup/SuccessPopup/SuccessPopup';
import ErrorPopup from '../Popup/ErrorPopup/ErrorPopup';
import { useEffect, useRef, useState } from 'react';
import ElementSlideFromLeft from '../ElementSlideFromLeft/ElementSlideFromLeft';

const cards = [
  {
    tariff: TARIFF_BASIC,
    title: 'Основной',
    price: '3.500',
    list: [
      'Cобственный дизайн',
      'QR-меню с фотографиями',
      'Заказы в заведении',
      'Уведомления в Telegram',
      'Вызов официанта',
      'Оплата счета',
      'Свой домен',
      'Премиум поддержка',
    ],
  },
  {
    tariff: TARIFF_PERSONAL,
    title: 'Персональный',
    subtitle: 'Условия обсуждаются индивидуально',
    list: [
      'Добавление дополнительных функций и модулей',
      'Персонализация под потребности вашего заведения',
      'Индивидуальные условия ценообразования',
    ],
  },
];

export default function Tariff({ activeIndex }) {
  const { width } = useWindowSize();
  const swiperRef = useRef(null);  // Реф для текущего Swiper
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 12) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SnapSection>
      <ScreenWrapper>
        <div
          className="tariff"
          id={TARIFF_ID}
          style={{
            '--swiper-theme-color': 'var(--swiper-main-color)',
            '--swiper-pagination-bullet-inactive-color':
              'var(--neutral-tertiary)',
            '--swiper-pagination-bottom': '16px',
            '--swiper-pagination-bullet-size': '8px',
            '--swiper-pagination-bullet-horizontal-gap': '8px',
            '--swiper-pagination-bullet-inactive-opacity': '1',
          }}
        >
          {width > 730 ? (
            cards.map((card) => <TariffCard key={card.title} card={card} />)
          ) : (
            <Swiper
              ref={swiperRef}
              className="tariff__swiper"
              slidesPerView={1.04}
              spaceBetween={10}
              // mousewheel={{ forceToAxis: true }}
              pagination={{ clickable: true }}
              modules={[Mousewheel, Pagination]}
              mousewheel={{
                releaseOnEdges: false,
                sensitivity: 0.6,
              }}
            >
              {cards.map((card) => (
                <SwiperSlide key={card.title}>
                  <TariffCard card={card} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        <ElementSlideFromTop elementInView={elementInView}>
          <div className="tariff__half-circle-wrapper">
            <HalfCircle
              color="var(--color-accent-sexdecenary)"
              rotateAngle="135deg"
            />
          </div>

          <div className="tariff__rhomb-wrapper">
            <RhombWithHole
              color="var(--accent-color-senary)"
              rhombStrokeWidth="23.24%"
            />
          </div>
        </ElementSlideFromTop>

        <ElementSlideFromTop elementInView={elementInView}>
          <div className="tariff__big-ring-wrapper">
            <Ring color="var(--accent-color-septenary)" />
          </div>

          <div className="tariff__small-ring-wrapper">
            <Ring
              color={
                width > 900
                  ? 'var(--color-accent-quattuordenary)'
                  : 'var(--accent-color-septendecenary)'
              }
            />
          </div>
        </ElementSlideFromTop>

        <ElementsAnimationWrapper>
          <div className="tariff__circle-flicker-wrapper">
            <ElementFlicker elementInView={elementInView}>
              <Circle color="var(--accent-color-octonary)" />
            </ElementFlicker>
          </div>

          <div className="tariff__rotate-wrapper">
            <ElementRotate elementInView={elementInView}>
              <HalfCircle
                color="var(--accent-color-nonary)"
                rotateAngle="45deg"
              />
            </ElementRotate>
          </div>

          <div className="tariff__half-circle-flicker-wrapper">
            <ElementFlicker elementInView={elementInView}>
              <HalfCircle
                color="var(--color-accent-octodenary)"
                rotateAngle="180deg"
              />
            </ElementFlicker>
          </div>
        </ElementsAnimationWrapper>

        {/* <ArrowSlideDown /> */}
        <SuccessPopup />
        <ErrorPopup />
      </ScreenWrapper>
    </SnapSection>
  );
}
