import Circle from '../Circle/Circle';
import ElementFlicker from '../ElementFlicker/ElementFlicker';
import ElementRotate from '../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromTop from '../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../HalfCircle/HalfCircle';
import RhombWithHole from '../RhombWithHole/RhombWithHole';
import Ring from '../Ring/Ring';
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper';
import SnapSection from '../SnapSection/SnapSection';
import Tag from '../Tag/Tag';
import PartnerCard from './PartnerCard/PartnerCard';
import './Partners.css';
import tngLogo from '../../assets/images/partners/tng.webp';
import unionLogo from '../../assets/images/partners/union.webp';
import {
  PARTNERS_TAG,
  TNG_MENU_LINK,
  TNG_SITE_LINK,
  UNION_MENU_LINK,
  UNION_SITE_LINK,
} from '../../assets/utils/constants';
import ArrowSlideDown from '../ArrowSlideDown/ArrowSlideDown';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { useEffect, useState } from 'react';

const cards = [
  {
    img: tngLogo,
    title: 'Tangiers Lounge',
    text: 'место для всех, кто ищет нетривиальный ресторан.',
    links: {
      menu: TNG_MENU_LINK,
      site: TNG_SITE_LINK,
    },
  },
  {
    img: unionLogo,
    title: 'Союз-99',
    text: 'Лаундж-бар, сочетающий в себе ретрофутуризм и современность.',
    links: {
      menu: UNION_MENU_LINK,
      site: UNION_SITE_LINK,
    },
  },
];

export default function Partners({ activeIndex }) {
  const { width } = useWindowSize();
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 13) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SnapSection>
      <ScreenWrapper>
        <Tag text={PARTNERS_TAG} />

        <div className="partners">
          {cards.map((card) => (
            <PartnerCard key={card.title} card={card} />
          ))}
        </div>

        <ElementSlideFromTop elementInView={elementInView}>
          <div className="partners__rhomb-wrapper">
            <RhombWithHole
              color="var(--accent-color-denary)"
              rhombStrokeWidth={width > 900 ? '13.34%' : '17.95%'}
            />
          </div>

          <div className="partners__ring-wrapper">
            <Ring
              color="var(--accent-color-primary)"
              innerSize={width > 900 ? '55%' : '50%'}
            />
          </div>

          <div className="partners__half-circle-wrapper">
            <HalfCircle
              color="var(--accent-color-quindenary)"
              rotateAngle="-60deg"
            />
          </div>
        </ElementSlideFromTop>

        <ElementsAnimationWrapper>
          <div className="partners__big-flicker-wrapper">
            <ElementFlicker elementInView={elementInView}>
              <Circle color="var(--color-accent-novemdecenary)" />
            </ElementFlicker>
          </div>

          <div className="partners__small-flicker-wrapper">
            <ElementFlicker elementInView={elementInView}>
              <Circle color="var(--accent-color-sexdenary)" />
            </ElementFlicker>
          </div>

          <div className="partners__rotate-wrapper">
            <ElementRotate elementInView={elementInView}>
              <HalfCircle
                color="var(--color-accent-octodenary)"
                rotateAngle="45deg"
              />
            </ElementRotate>
          </div>
        </ElementsAnimationWrapper>

        {/* <ArrowSlideDown /> */}
      </ScreenWrapper>
    </SnapSection>
  );
}
