import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import './Features.css';
import slideImg from '../../../assets/images/digital-menu/slide2.webp';
import slideIcon from '../../../assets/icons/digital-menu/slide2.png';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Функция вызова официанта:',
    text: 'Клиенты могут вызвать официанта непосредственно через интерфейс меню.',
  },
  {
    title: 'Самостоятельный заказ позиций к столу:',
    text: 'Клиенты могут оформлять заказы напрямую к&nbsp;своему столу без участия персонала.',
  },
];

export default function Features({ activeIndex, slideTo }) {
  const { width } = useWindowSize();

  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 2) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText}>
        <img className="features__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="features__big-half-circle-wrapper">
          <Circle color="var(--color-accent-senary)" rotateAngle="180deg" />
        </div>
        <div className="features__small-half-circle-wrapper">
          <HalfCircle color="var(--website-background)" />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="features__ring-wrapper">
          <Ring color="var(--color-accent-quinary)" />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="features__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-septenary)" />
          </ElementFlicker>
        </div>

        <div className="features__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <RhombWithHole
              color="var(--color-accent-octonary)"
              rhombStrokeWidth={width > 900 ? '21.48%' : '24.07%'}
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
