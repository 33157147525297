import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import LinkButton from '../../LinkButton/LinkButton';
import './StoreTextElement.css';

export default function StoreTextElement({ text, currentSlideBtn, slideTo }) {
  const { title, description, price } = text;

  return (
    <ElementFromOpacity>
      <div className="store-text-element">
        <p className="store-text-element__title">{title}</p>
        <p
          className="store-text-element__text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <p className="store-text-element__price">
          {price} <span className="store-text-element__currency">₽</span>
        </p>
        <div className="store-text-element__button-wrapper">
          <LinkButton isStore id={currentSlideBtn} slideTo={slideTo}/>
        </div>
      </div>
    </ElementFromOpacity>
  );
}
