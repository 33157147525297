import { MAIN_API_URL } from '../utils/constants';

class MainApi {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }
 
  sendContactForm({ name, phone, email, tariff, clicked_btn, referrer, utm }) {
    let params = {};
    if (name) params.name = name;
    if (phone) params.phone = phone;
    if (email) params.email = email;
    if (tariff) params.tariff = tariff;
    if (clicked_btn) params.clicked_btn = clicked_btn;
    if (referrer) params.referrer = referrer
    if (utm) params.utm = utm

    return fetch(`${this._baseUrl}?` + new URLSearchParams(params), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_API_URL,
});

export default mainApi;
