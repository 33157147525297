import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Admin.css';
import slideImg from '../../../assets/images/staff/slide1.webp';
import slideIcon from '../../../assets/icons/staff/slide1.png';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Приложение для персонала:',
    text: 'Веб-приложение, доступное на любом устройстве с интернет-соединением, для управления заказами и взаимодействия с клиентами.',
  },
  {
    title: 'Удобная административная панель:',
    text: 'Интуитивно понятный интерфейс для управления меню, добавления товаров и контроля доступа сотрудников.',
  },
];

export default function Admin({ activeIndex,slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 9) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText}>
        <div className="admin__slide-image-wrapper">
          <img className="admin__slide-image" src={slideImg} alt="" />
        </div>
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="admin__half-circle-wrapper">
          <HalfCircle
            color="var(--color-accent-decenary)"
            rotateAngle="-60deg"
          />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="admin__ring-wrapper">
          <Ring color="var(--accent-color-primary)" />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="admin__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--accent-color-secondary)" />
          </ElementFlicker>
        </div>

        <div className="admin__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <RhombWithHole
              color="var(--accent-color-tertiary)"
              rhombStrokeWidth="24.18%"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
