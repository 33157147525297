export default function usePhoneFormat() {
  function formatPhoneNumber(value) {
    let inputValue = value.replace(/\D/g, '');
    let formattedValue = '';
    const validity = {
      errorMessage: '',
      validState: false,
    };

    if (!inputValue) {
      formattedValue = '';
      validity.errorMessage = 'Можно вводить только цифры';
      validity.validState = false;

      return { formattedValue, validity };
    } else {
      if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
        validity.errorMessage = '';
        validity.validState = false;

        if (inputValue[0] === '9') inputValue = '7' + inputValue;

        let firstSimbols = inputValue[0] === '8' ? '8' : '+7';
        formattedValue = firstSimbols + ' ';

        if (inputValue.length > 1) {
          formattedValue += '(' + inputValue.substring(1, 4);
        }

        if (inputValue.length >= 5) {
          formattedValue += ') ' + inputValue.substring(4, 7);
        }

        if (inputValue.length >= 8) {
          formattedValue += '-' + inputValue.substring(7, 9);
        }

        if (inputValue.length >= 10) {
          formattedValue += '-' + inputValue.substring(9, 11);
        }

        if (inputValue.length >= 11) {
          validity.errorMessage = '';
          validity.validState = true;
        } else {
          validity.errorMessage = '';
          validity.validState = false;
        }
      } else {
        formattedValue = '+' + inputValue.substring(0, 16);
        if (inputValue.length >= 11) {
          validity.errorMessage = 'invalid phone number';
          validity.validState = false;
        } else {
          validity.errorMessage = '';
          validity.validState = true;
        }
      }

      return { formattedValue, validity };
    }
  }

  function deletePhoneNumber({ e, value, validity }) {
    const inputValue = e.target.value;
    let formattedValue = value;
    const inputValidity = validity;

    if (e.keyCode === 8 && inputValue.replace(/\D/g, '').length === 1) {
      formattedValue = '';
    }

    if (e.keyCode === 8 && inputValue.replace(/\D/g, '').length < 11) {
      inputValidity.errorMessage = '';
      inputValidity.validState = false;
    }

    return { formattedValue, validity: inputValidity };
  }

  return { formatPhoneNumber, deletePhoneNumber };
}
