import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './CartCounter.css';
import { useContext } from 'react';
import { CartContext } from '../../../assets/contexts/cartContext';

const counterMotion = {
  upper: { opacity: 1, y: '0px' },
  base: { opacity: 0, y: '100%' },
};

function CartCounter({setOpenCart, openCart}) {
  const { cart } = useContext(CartContext);

  return cart.length > 0 ? (
    <motion.div
      animate={cart.length > 0 ? 'upper' : 'base'}
      variants={counterMotion}
      className="cart-counter"
    >
      <div onClick={() => setOpenCart(true)} className="cart-counter__button-to-cart">
        <p className="cart-counter__position-number">
          Позиций:
          <span className="cart-counter__number">{Number(cart.length)}</span>
        </p>
        <p className="cart-counter__button-text">Заказать</p>
      </div>
    </motion.div>
  ) : null;
}

export default CartCounter;
