import { SUCCESS_POPUP_ID } from '../../../assets/utils/constants';
import Popup from '../Popup';
import './SuccessPopup.css';

export default function SuccessPopup() {
  return (
    <div className="success-popup" popover="auto" id={SUCCESS_POPUP_ID}>
      <Popup text="Вы&nbsp;успешно оформили заказ" id={SUCCESS_POPUP_ID} />
    </div>
  );
}
