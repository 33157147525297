import { useContext, useState } from 'react';
import './ProductCard.css';
import { AddIcon, CheckIcon } from '../../../../assets/icons/icons';
import { CartContext } from '../../../../assets/contexts/cartContext';
import { productIdStorageKey } from '../../../../assets/utils/constants';
import { motion } from "framer-motion"
import { compareOptions } from '../../../../assets/utils/utils';

function ProductCard({ product, index }) {
  const { setCart } = useContext(CartContext);
  let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];


  function handleAddToCart(item) {
    // Retrieve the cart from localStorage and parse it; initialize as empty array if not found
    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

    // Define a helper function to compare item options
    function isSameItemOptions(cartItem, newItem) {
      if (
        !newItem.options ||
        newItem.options.length === 0 ||
        !cartItem.options ||
        cartItem.options.length === 0
      ) {
        return true; // Treat as same if either item doesn't have options
      }
      const comparisonResult = compareOptions(
        newItem.options,
        cartItem.options
      );
      return comparisonResult.differences.length === 0;
    }

    // Find if the item already exists in the cart with the same options
    const existingCartItemIndex = cartArray.findIndex(
      (cartItem) => cartItem.id === item.id && isSameItemOptions(cartItem, item)
    );
    if (existingCartItemIndex !== -1) {
      // Item with same ID and options found, increase count
      cartArray[existingCartItemIndex].count += 1;
    } else {
      // No matching item found, add new item to cart
      // console.log(item.prices[0].price)
      const newItem = {
        id: item.id,
        price: item.prices[0].price,
        //options: item.options || [], // Ensure options is always an array
        count: 1,
      };
      cartArray.push(newItem);
    }

    // Update the cart in both local state and localStorage
    setCart(cartArray);
    localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    //console.log(cartArray)
  }

  function handleRemoveFromCart(itemToRemove, isAll) {

    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

    // Define a helper function to compare item options for equality
    function isSameItemOptions(cartItem, item) {
      if (!item.options || item.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
        return true; // Consider items the same if either has no options
      }
      const comparisonResult = compareOptions(item.options, cartItem.options);
      return comparisonResult.differences.length === 0;
    }
    const itemIndex = cartArray.findIndex(cartItem =>
      cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove));

    if (isAll) {
      //Remove all instances of the item with the same options

      cartArray = cartArray.filter(cartItem =>
        !(cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove)));
    } else {
      // Find the index of the item to decrement quantity or remove

      if (itemIndex !== -1) {
        if (cartArray[itemIndex].count > 1) {
          // Decrement the count
          cartArray[itemIndex].count -= 1;

        } else {
          // Remove the item completely if count is 1
          cartArray.splice(itemIndex, 1);
        }
      }
    }
    //Update the cart in both local state and localStorage
    setCart(cartArray);
    localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
  }

  return (
    <li className="product-card">
      <div className="product-card__info">
        <p className="product-card__title">{product.title} {product.prices.map((item, i) => (
          <span className='product-card__title__size'>{item.measure}{i + 1 === product.prices.length ? null : ` / `}</span>
        ))}</p>
        {product.desciption ? (
          <p className="product-card__text">{product.desciption}</p>
        ) : null}
        <p className="product-card__price">
          {product.prices.map((item, i) => (
            <>
              {item.price}
              <span className="product-card__price_size_small">₽</span>
              {i + 1 === product.prices.length ? null : ` / `}
            </>
          ))}

        </p>
      </div>
      {cartArray.find(city => city.id === product.id) ?
        <motion.button
          className="product-card__add-btn product-card__add-btn_checked"
          type="button"
          id={index}
          onClick={() => handleRemoveFromCart(product, true)}
        >
          <CheckIcon id={index} mainClassName="product-card__btn-icon" />
        </motion.button>
        :
        <motion.button
          className="product-card__add-btn product-card__add-btn_add"
          type="button"
          id={index}
          onClick={() => handleAddToCart(product)}
        >
          <AddIcon id={index} mainClassName="product-card__btn-icon" />
        </motion.button>
      }
    </li>
  );
}

export default ProductCard;
