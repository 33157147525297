import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Catalog from '../Catalog/Catalog';
import Loading from '../Loading/Loading';
import './Menu.css';
import { useEffect, useState } from 'react';
import SubcategotyPageTemplate from '../SubcategoryPageTemplate/SubcategoryPageTemplate';
import Cart from '../Cart/Cart';
import { CartContext } from '../../../assets/contexts/cartContext';
import OrderHistory from '../OrderHistory/OrderHistory';
import Header from '../Header/Header';
// import mainApi from '../../../assets/api/MainApi';
import { tab } from "@testing-library/user-event/dist/tab";

import { tableStorageKey } from '../../../assets/utils/constants';
import Rules from '../Rules/Rules';

function Menu({ setAddNew, addNew, barActive, setBarActive, menuActive, setMenuActive, billActive, setBillActive }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [isPayOpen, setPayOpen] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [isOpenHistory, setOpenHistory] = useState(false);
  // const [isLoading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const table = searchParams.get('table');
  const [realTable, setRealTable] = useState(null);

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const location = useLocation()
  console.log(location.pathname)

  useEffect(() => {
    //   if (!table && location.pathname === '/') {
    //     localStorage.removeItem('table');
    //   }
    //   if (!isNumeric(table)) {
    //     // localStorage.removeItem('table');
    //     setRealTable(null);
    //   } else {
    //     localStorage.setItem('table', JSON.stringify(table));
    //     setRealTable(parseInt(table));
    //   }
    // }, [table]);

    // useEffect(() => {
    //   let realTableLocal = JSON.parse(localStorage.getItem('table'));
    //   if (!realTableLocal || !isNumeric(realTableLocal) || realTableLocal > 12 || realTableLocal < 1) {
    //     localStorage.removeItem('table');
    //   } else {
    //     setRealTable(parseInt(realTableLocal));
    //   }
    setRealTable(1)
    localStorage.setItem('table', JSON.stringify(1));

  }, []);

  // setTimeout(() => {
  //   setLoading(true);
  //   // navigate("/catalog")
  // }, 2000);

  const [cart, setCart] = useState([]);
  useEffect(() => {
    // Получаем корзину из localStorage с учетом shop_id
    let cartArray = JSON.parse(localStorage.getItem('products_id')) || [];
    setCart(cartArray);
    console.log(cartArray);
  }, []);

  setTimeout(() => {
    localStorage.removeItem(tableStorageKey)
  }, 7200000);

  const [catalog, setCatalog] = useState([]);

  function getCategory() {
    // setIsPreloaderVisible(true);
    // mainApi
    //   .getAllCategories()
    //   .then((res) => {
    //     setCatalog(res);
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    // .finally(() => {
    // setIsPreloaderVisible(false);
    // });
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="menu">
      <CartContext.Provider value={{ cart, setCart }}>
        <Header
          setOpenMenu={setOpenMenu}
          openMenu={openMenu}
          isOpenHistory={isOpenHistory}
          setOpenHistory={setOpenHistory} />
        <Routes>
          {/* <Route path="/" element={isLoading ? <Loading /> : null} /> */}
          <Route
            path="/"
            element={
              <Catalog
                setOpenMenu={setOpenMenu}
                openMenu={openMenu}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                catalog={catalog}
                openCart={openCart}
                setOpenCart={setOpenCart}
                setAddNew={setAddNew}
                addNew={addNew}
                setPayOpen={setPayOpen}
                isPayOpen={isPayOpen}
                setBarActive={setBarActive}
                barActive={barActive}
                setMenuActive={setMenuActive}
                menuActive={menuActive}
                setBillActive={setBillActive}
                billActive={billActive}
                isOpenHistory={isOpenHistory}
                setOpenHistory={setOpenHistory}
              />
            }
          />
          {/* <Route path="/catalog/:id" element={<SubcategotyPageTemplate />} /> */}
          {/* <Route path='/catalog/rules' element={<Rules />}></Route> */}
          {/* <Route path="/cart" element={<Cart />} /> */}
          {/* <Route path="/history" element={<OrderHistory />} /> */}
        </Routes>
      </CartContext.Provider>
    </div>
  );
}

export default Menu;
