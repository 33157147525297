import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import SlideContent from '../../SlideContent/SlideContent';
import './MenuDescription.css';
import slideImg from '../../../assets/images/digital-menu/slide1.webp';
import slideIcon from '../../../assets/icons/digital-menu/slide1.png';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import { useEffect, useRef, useState } from 'react';

const slideText = [
  {
    title: 'Онлайн-меню по QR-коду:',
    text: 'Предоставление клиентам доступа к меню путем&nbsp;сканирования QR-кода или перехода по&nbsp;прямой&nbsp;ссылке.',
  },
  {
    title: 'Большие фотографии и описания блюд:',
    text: 'Возможность детального визуального представления позиций меню для&nbsp;облегчения&nbsp;выбора клиентами.',
  },
];

export default function MenuDescription({ activeIndex,slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 1) {
      setElementIsInView(true)
    }
  }, [activeIndex])


  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText}>
        <img className="menu-description__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="menu-description__rhomb-wrapper">
          <RhombWithHole
            color="var(--color-accent-primary)"
            rhombStrokeWidth="25.88%"
          />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="menu-description__circle-wrapper">
          <Circle color="var(--color-accent-secondary)" />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="menu-description__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-tertiary)" />
          </ElementFlicker>
        </div>

        <div className="menu-description__rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-quaternary)"
              rotateAngle="135deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
