import { useEffect, useState } from 'react';
import { DEMO_TAG, DIGITAL_MENU_TAG, OPTIONS_TAG, PERSONALIZATION_TAG, STAFF_TAG } from '../../assets/utils/constants';
import ArrowSlideDown from '../ArrowSlideDown/ArrowSlideDown';
import Demo from '../Demo/Demo';
import Footer from '../Footer/Footer';
import Heading from '../Heading/Heading';
import Integrations from '../Options/Integrations/Integrations';
import Notifications from '../Options/Notifications/Notifications';
import Payment from '../Options/Payment/Payment';
import Partners from '../Partners/Partners';
import Design from '../Personalization/Design/Design';
import Icons from '../Personalization/Icons/Icons';
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper';
import SnapSection from '../SnapSection/SnapSection';
import Admin from '../Staff/Admin/Admin';
import Telegram from '../Staff/Telegram/Telegram';
import Updates from '../Staff/Updates/Updates';
import Tablet from '../Store/Tablet/Tablet';
import Watch from '../Store/Watch/Watch';
import SwiperWrapper from '../SwiperWrapper/SwiperWrapper';
import Tag from '../Tag/Tag';
import Tariff from '../Tariff/Tariff';
import CashSystem from './CashSystem/CashSystem';
import './DigitalMenu.css';
import Features from './Features/Features';
import MenuDescription from './MenuDescription/MenuDescription';
import Menu from '../Menu/Menu/Menu';
import TableApp from '../TableApp/TableApp/TableApp';
import useWindowSize from '../../assets/hooks/useWindowSize';

export default function DigitalMenu({ digitalMenuEnd, setDigitalMenuEnd, slideTo, swiper, setSwiper }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const window = useWindowSize()

  const tagMapping = [
    { range: [1, 4], text: DIGITAL_MENU_TAG },
    { range: [4, 7], text: OPTIONS_TAG },
    { range: [7, 9], text: PERSONALIZATION_TAG },
    { range: [9, 12], text: STAFF_TAG },
    { range: [14, 15], text: DEMO_TAG },
  ];
  const tag = tagMapping.find(({ range }) => activeIndex >= range[0] && activeIndex < range[1]);
  const [addNew, setAddNew] = useState(false)
  const [barActive, setBarActive] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const [billActive, setBillActive] = useState(false)
  const [elementInView, setElementIsInView] = useState(false)

  return (
    <SnapSection>
      <ScreenWrapper>
        {tag ? <Tag text={tag.text} /> : null}
        {activeIndex === 14 && window.width > 1400 ?
          <Menu
            barActive={barActive} setBarActive={setBarActive}
            menuActive={menuActive} setMenuActive={setMenuActive}
            billActive={billActive} setBillActive={setBillActive}
            addNew={addNew} setAddNew={setAddNew}
          />
          : null}
        {activeIndex === 14 && window.width > 1400 ?
          <TableApp
            barActive={barActive} setBarActive={setBarActive}
            menuActive={menuActive} setMenuActive={setMenuActive}
            billActive={billActive} setBillActive={setBillActive}
            addNew={addNew} setAddNew={setAddNew}
          />
          : null}
        <SwiperWrapper
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          isEnd={digitalMenuEnd}
          setIsEnd={setDigitalMenuEnd}
          id={1}
          swiper={swiper}
          setSwiper={setSwiper}
          slides={[
            <Heading slideTo={slideTo} activeIndex={activeIndex} />,
            <MenuDescription slideTo={slideTo} activeIndex={activeIndex} />,
            <Features slideTo={slideTo} activeIndex={activeIndex} />,
            <CashSystem slideTo={slideTo} activeIndex={activeIndex} />,
            <Notifications slideTo={slideTo} activeIndex={activeIndex} />,
            <Payment slideTo={slideTo} activeIndex={activeIndex} />,
            <Integrations slideTo={slideTo} activeIndex={activeIndex} />,
            <Design slideTo={slideTo} activeIndex={activeIndex} />,
            <Icons slideTo={slideTo} activeIndex={activeIndex} />,
            <Admin slideTo={slideTo} activeIndex={activeIndex} />,
            <Telegram slideTo={slideTo} activeIndex={activeIndex} />,
            <Updates slideTo={slideTo} activeIndex={activeIndex} />,
            <Tariff slideTo={slideTo} activeIndex={activeIndex} />,
            <Partners slideTo={slideTo} activeIndex={activeIndex} />,
            <Demo
              barActive={barActive} setBarActive={setBarActive}
              menuActive={menuActive} setMenuActive={setMenuActive}
              billActive={billActive} setBillActive={setBillActive}
              addNew={addNew} setAddNew={setAddNew}
              slideTo={slideTo} activeIndex={activeIndex}
            />,
            <Tablet slideTo={slideTo} activeIndex={activeIndex} />,
            <Watch slideTo={slideTo} activeIndex={activeIndex} />,
            <Footer slideTo={slideTo} activeIndex={activeIndex} />
          ]}
        />
      </ScreenWrapper>
      {(activeIndex === 17 || activeIndex === 0) ?
        null :
        <ArrowSlideDown />
      }
    </SnapSection>
  );
}
