import './CartSecondStep.css';
import { Link } from 'react-router-dom';

function CartSecondStep({setOpenCart}) {
    return (
        <div className='cart-second-step'>
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="134" viewBox="0 0 63 134" fill="none">
                <path d="M27.3445 3.19042C28.9031 0.936527 32.0973 0.936527 33.6559 3.19042C39.4047 11.5037 42.5004 21.4832 42.5004 31.7254L42.5004 60C42.5004 62.5379 40.5304 64.5009 38.2146 64.5009L22.7858 64.5009C20.4699 64.5009 18.5 62.5379 18.5 60L18.5 31.7254C18.5 21.4832 21.5957 11.5037 27.3445 3.19042Z" stroke="#777786" strokeWidth="3" />
                <path d="M18.0002 33L4.17539 40.0877C2.84008 40.7722 2.00024 42.1466 2.00024 43.6471L2.00024 63" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                <path d="M44.0005 33.1805L58.4014 40.2366C59.7723 40.9084 60.6414 42.3019 60.6414 43.8286L60.6414 63.0008" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                <path d="M2.00024 55.0014L17.0005 55.0014" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                <path d="M60.6414 55.0014L45.6411 55.0014" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                <path d="M54.0749 2.6082C54.2403 2.24209 54.7602 2.24209 54.9255 2.60821L56.9686 7.13177C57.0153 7.23531 57.0983 7.31823 57.2018 7.36499L61.7254 9.40804C62.0915 9.5734 62.0915 10.0933 61.7254 10.2587L57.2018 12.3017C57.0983 12.3485 57.0153 12.4314 56.9686 12.5349L54.9255 17.0585C54.7602 17.4246 54.2403 17.4246 54.0749 17.0585L52.0319 12.5349C51.9851 12.4314 51.9022 12.3485 51.7986 12.3017L47.2751 10.2587C46.909 10.0933 46.909 9.5734 47.2751 9.40804L51.7986 7.36499C51.9022 7.31823 51.9851 7.23531 52.0319 7.13177L54.0749 2.6082Z" fill="#555B7D" />
                <path d="M32.002 84.0012L32.002 132.002" stroke="#555B7D" strokeWidth="3.6" strokeLinecap="round" />
                <path d="M14.0022 84.0012L14.0022 120.002" stroke="#555B7D" strokeWidth="3.6" strokeLinecap="round" />
                <path d="M50.0017 84.0011L50.0017 120.002" stroke="#555B7D" strokeWidth="3.6" strokeLinecap="round" />
            </svg>
            <p className='cart-second-step__super'>супер!</p>
            <p className=' cart-second-step__title'>Мы начали готовить ваш заказ.</p>
            <p className='cart-second-step__subtitle'>Посмотреть детали вашего заказа вы можете
                в правом верхнем углу.</p>
            <button onClick={() => setOpenCart(false)} className='cart__button-send'>Oк</button>
        </div>
    );
}

export default CartSecondStep