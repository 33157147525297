import './Rhomb.css';

export default function Rhomb({ color, shadowColor = undefined }) {
  return (
    <div
      className="square"
      style={{
        '--rhomb-color': color,
        '--size-divider': Math.SQRT2,
        '--shadow-color': shadowColor ?? 'none',
      }}
    >
      <div className="square__rhomb" />
    </div>
  );
}
