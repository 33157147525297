import { ERROR_POPUP_ID } from '../../../assets/utils/constants';
import Popup from '../Popup';
import './ErrorPopup.css';

export default function ErrorPopup() {
  return (
    <div className="error-popup" popover="auto" id={ERROR_POPUP_ID}>
      <Popup
        text="При создании заказа произошла ошибка"
        subtext="Пожалуйста, попробуйте позже"
        isError
        id={ERROR_POPUP_ID}
      />
    </div>
  );
}
