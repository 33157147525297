import { motion } from 'framer-motion';
import { CheckIcon } from '../../assets/icons/icons';
import './Checkbox.css';

export default function Checkbox({ onChange, name, checked, label, error ,id}) {
  return (
    <div className="checkbox">
      <div className="checkbox__container">
        <label className="checkbox__box" htmlFor={id}>
          <input
            className="checkbox__invisible-input"
            id={id}
            name={name}
            type="checkbox"
            value={checked}
            checked={checked}
            onChange={onChange}
          />
          <span
            className={`checkbox__pseudo-item ${
              checked ? 'checkbox__pseudo-item_checked' : ''
            }`}
          >
            {CheckIcon({
              mainClassName: 'checkbox__check-icon',
              fillClassName: 'checkbox__check-icon-fill',
              checkClassName: 'checkbox__check-icon-stroke',
            })}
          </span>
        </label>
        <span
          className="checkbox__label"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>

      <motion.div
        className="checkbox__error-box"
        initial={{
          height: '0px',
          marginTop: '0px',
          opacity: 0,
          visibility: 'hidden',
        }}
        animate={{
          height: error ? '14px' : '0px',
          marginTop: error ? '4px' : '0px',
          opacity: error ? 1 : 0,
          visibility: error ? 'visible' : 'hidden',
        }}
        transition={{ duration: 0.15 }}
      >
        {error && <p className="checkbox__error">{error}</p>}
      </motion.div>
    </div>
  );
}
