import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import './TariffCard.css';
import TariffCardContent from '../TariffCardContent/TariffCardContent';
import TariffCardForm from '../TariffCardForm/TariffCardForm';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

export default function TariffCard({ card }) {
  const [step, setStep] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);

  function handleNext() {
    setStep(step + 1);
  }

  function handleBack() {
    setStep(step - 1);
  }

  return (
    <ElementFromOpacity>
      <div className="tariff-card">
        <AnimatePresence initial={false} mode="popLayout">
          {step === 0 ? (
            <motion.div
              className="tariff-card__content"
              key="tariff"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <TariffCardContent {...card} />
            </motion.div>
          ) : (
            <motion.div
              className="tariff-card__content"
              key="form"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <TariffCardForm
                {...card}
                handleBack={handleBack}
                isFormValid={isFormValid}
                setIsFormValid={setIsFormValid}
                isPreloader={isPreloader}
                setIsPreloader={setIsPreloader}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <motion.button
          className={`tariff-card__button ${
            (step === 1 && !isFormValid) || isPreloader
              ? 'tariff-card__button_disabled'
              : ''
          }`}
          type={step === 0 ? 'button' : 'submit'}
          onClick={step === 0 ? handleNext : undefined}
          disabled={(step === 1 && !isFormValid) || isPreloader}
          form={step === 1 ? 'tariff-form' : undefined}
          initial={{ backgroundColor: 'var(--tariff-button-color)' }}
          animate={{
            backgroundColor:
              step === 0
                ? 'var(--tariff-button-color)'
                : step === 1 && isFormValid
                ? 'var(--form-button-color)'
                : 'var(--form-button-disabled)',
          }}
          whileHover={{
            backgroundColor:
              step === 0
                ? 'var(--tariff-button-hover)'
                : 'var(--form-button-hover)',
          }}
          whileTap={{
            backgroundColor:
              step === 0
                ? 'var(--tariff-button-active)'
                : 'var(--form-button-active)',
          }}
        >
          <AnimatePresence initial={false} mode="popLayout">
            {step === 0 ? (
              <motion.span
                className="tariff-card__button-text"
                key="tariff"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                Оформить
              </motion.span>
            ) : isPreloader ? (
              <div
                className="tariff-card__button-preloader"
                key="preloader"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <MiniPreloader isLinkColor />
              </div>
            ) : (
              <motion.span
                className="tariff-card__button-text"
                key="form"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                Отправить
              </motion.span>
            )}
          </AnimatePresence>
        </motion.button>
      </div>
    </ElementFromOpacity>
  );
}
