import { useRef, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './ElementFromOpacity.css';
import { ANIMATION_DELAY, ANIMATION_DURATION } from '../../assets/utils/constants';

const animationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: ANIMATION_DURATION- ANIMATION_DELAY, delay: ANIMATION_DELAY } },
};

export default function ElementFromOpacity({ children }) {
  const elementRef = useRef(null);
  const elementInView = useInView(elementRef, { once: true, amount: 0.05 });
  const elementControls = useAnimation();

  useEffect(() => {
    if (elementInView) {
      elementControls.start('visible');
    }
  }, [elementInView, elementControls]);

  return (
    <motion.div
      ref={elementRef}
      variants={animationVariants}
      animate={elementControls}
      initial={false}
      className="element-from-opacity"
    >
      {children}
    </motion.div>
  );
}
