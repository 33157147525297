import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementRotate from '../../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromBottom from '../../ElementSlideFromBottom/ElementSlideFromBottom';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import Rhomb from '../../Rhomb/Rhomb';
import Ring from '../../Ring/Ring';
import SlideContent from '../../SlideContent/SlideContent';
import './CashSystem.css';
import slideImg from '../../../assets/images/digital-menu/slide3.webp';
import slideIcon from '../../../assets/icons/digital-menu/slide3.png';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Интеграция с кассовой системой:',
    text: 'Синхронизация цен и позиций меню с&nbsp;вашей&nbsp;кассовой системой для&nbsp;обеспечения&nbsp;актуальности данных.',
  },
];

export default function CashSystem({ activeIndex, slideTo }) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 3) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText} withScrollBtn>
        <img className="cash-system__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="cash-system__rhomb-wrapper">
          <Rhomb color="var(--color-accent-nonary)" />
        </div>
      </ElementSlideFromTop>

      <ElementSlideFromBottom elementInView={elementInView}>
        <div className="cash-system__ring-wrapper">
          <Ring color="var(--color-accent-denary)" />
        </div>
      </ElementSlideFromBottom>

      <ElementsAnimationWrapper>
        <div className="cash-system__flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-septenary)" />
          </ElementFlicker>
        </div>

        <div className="cash-system__rhomb-rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <Rhomb color="var(--color-accent-duodenary)" />
          </ElementRotate>
        </div>

        <div className="cash-system__half-circle-rotate-wrapper">
          <ElementRotate elementInView={elementInView}>
            <HalfCircle
              color="var(--color-accent-tredenary)"
              rotateAngle="135deg"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
