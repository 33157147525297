import { useRef, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './ElementSlideFromTop.css';
import { ANIMATION_DELAY, ANIMATION_DURATION } from '../../assets/utils/constants';

const animationVariants = {
  hidden: { y: '-15%', opacity: 0 },
  visible: {
    y: '10%',
    opacity: 1,
    transition: { duration: ANIMATION_DURATION, type: 'spring' },
  },
};

export default function ElementSlideFromTop({ children, elementInView }) {
  const elementRef = useRef(null);
  // const elementInView = useInView(elementRef, { once: true, amount: 0.8 });
  const elementControls = useAnimation();

  useEffect(() => {
    if (elementInView) {
      elementControls.start('visible');
    }
  }, [elementControls, elementInView]);

  return (
    <motion.div
      ref={elementRef}
      variants={animationVariants}
      animate={elementControls}
      initial="hidden"
      className="element-slide-from-top"
    >
      {children}
    </motion.div>
  );
}

