import Circle from '../../Circle/Circle';
import ElementFlicker from '../../ElementFlicker/ElementFlicker';
import ElementsAnimationWrapper from '../../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromTop from '../../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../../HalfCircle/HalfCircle';
import RhombWithHole from '../../RhombWithHole/RhombWithHole';
import SlideContent from '../../SlideContent/SlideContent';
import SlideWrapper from '../../SlideWrapper/SlideWrapper';
import './Icons.css';
import slideImg from '../../../assets/images/personalization/slide2.webp';
import slideIcon from '../../../assets/icons/personalization/slide2.png';
import ElementRotate from '../../ElementRotate/ElementRotate';
import { useEffect, useState } from 'react';

const slideText = [
  {
    title: 'Набор иконок для категорий:',
    text: 'Предоставление уникальных иконок для&nbsp;категорий меню, соответствующих вашему&nbsp;бренду.',
  },
  {
    title: 'Размещение на собственном домене:',
    text: 'Возможность размещения онлайн-меню на&nbsp;вашем собственном доменном имени.',
  },
];

export default function Icons({ activeIndex ,slideTo}) {
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 8) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SlideWrapper>
      <SlideContent slideTo={slideTo} icon={slideIcon} text={slideText}>
        <img className="icons__slide-image" src={slideImg} alt="" />
      </SlideContent>

      <ElementSlideFromTop elementInView={elementInView}>
        <div className="icons__half-circle-wrapper">
          <HalfCircle
            color="var(--color-accent-sexdecenary)"
            rotateAngle="135deg"
          />
        </div>
      </ElementSlideFromTop>

      <ElementsAnimationWrapper>
        <div className="icons__big-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-octodecenary)" />
          </ElementFlicker>
        </div>

        <div className="icons__small-flicker-wrapper">
          <ElementFlicker elementInView={elementInView}>
            <Circle color="var(--color-accent-novemdecenary)" />
          </ElementFlicker>
        </div>

        <div className="icons__rhomb-wrapper">
          <ElementRotate elementInView={elementInView}>
            <RhombWithHole
              color="var(--color-accent-septendecenary)"
              rhombStrokeWidth="24.72%"
            />
          </ElementRotate>
        </div>
      </ElementsAnimationWrapper>
    </SlideWrapper>
  );
}
