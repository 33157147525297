import { useEffect, useState } from 'react';
import DigitalMenu from '../DigitalMenu/DigitalMenu';
import Footer from '../Footer/Footer';
import Heading from '../Heading/Heading';
import Options from '../Options/Options';
import Partners from '../Partners/Partners';
import Personalization from '../Personalization/Personalization';
import Staff from '../Staff/Staff';
import Store from '../Store/Store';
import Tariff from '../Tariff/Tariff';
import './App.css';
import { Route, Router, Routes, useSearchParams } from "react-router-dom";
import Menu from '../Menu/Menu/Menu';
import Demo from '../Demo/Demo';
import SnapSection from '../SnapSection/SnapSection';

function App() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (!localStorage.getItem('referrer')) {
    const referrer = document.referrer;
    if (referrer) localStorage.setItem('referrer', referrer)
  }

  const [isUTMHandled, setIsUTMHandled] = useState(false); // Flag to control one-time execution
  const handleUTMParams = () => {
    const utmTags = {};
    let hasUTM = false;
    const date = new Date().toISOString();
    // Consolidate UTM parameters into the utmTags object
    searchParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmTags[key] = value;
        hasUTM = true;
      }
    });
    if (hasUTM) {
      utmTags.date = date; // Add date only if UTM tags are found
      // Retrieve or initialize utm_history array in localStorage
      const utmHistory = JSON.parse(localStorage.getItem('utm_history')) || [];
      // Check if an identical entry exists in utm_history
      const isDuplicate = utmHistory.some(
        (entry) => {
          // Check if the current entry matches all UTM parameters in utmTags
          const { date: entryDate, ...entryWithoutDate } = entry;
          const { date: utmDate, ...utmTagsWithoutDate } = utmTags;
          return JSON.stringify(entryWithoutDate) === JSON.stringify(utmTagsWithoutDate);
        }
      );
      if (!isDuplicate) {
        utmHistory.unshift(utmTags); // Add new UTM tags as the latest entry
        localStorage.setItem('utm_history', JSON.stringify(utmHistory)); // Save updated history
      }
      // Remove UTM parameters from URL after saving
      searchParams.forEach((_, key) => {
        if (key.startsWith('utm_')) {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
    }
  };
  // Execute UTM handling only if not already handled
  if (!isUTMHandled) {
    handleUTMParams();
    setIsUTMHandled(true); // Set the flag to true to prevent re-running
  }
  const [digitalMenuEnd, setDigitalMenuEnd] = useState(false);
  // const [optionsEnd, setOptionsEnd] = useState(false);
  // const [persEnd, setPersEnd] = useState(false);
  // const [staffEnd, setStaffEnd] = useState(false);
  // const [tariffEnd, setTariffEnd] = useState(false);
  // const [storeEnd, setStoreEnd] = useState(false);
  console.log(digitalMenuEnd)

  useEffect(() => {
    if (digitalMenuEnd) {

    }
  }, [digitalMenuEnd])

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
    if (swiper)
      swiper.slideTo(index)
  };

  return (
    <Routes>
      <Route
        path='/'
        element={
          <div className="app">
            {/* <Heading /> */}
            <DigitalMenu slideTo={slideTo} swiper={swiper} setSwiper={setSwiper} digitalMenuEnd={digitalMenuEnd} setDigitalMenuEnd={setDigitalMenuEnd} />
            {/* {digitalMenuEnd ?
              <Footer digitalMenuEnd={digitalMenuEnd} />
              : null} */}
          </div>}
      />
    </Routes>
  );
}

export default App;
