import { PHOTO_URL } from '../../../../assets/utils/constants';
import ProductCard from '../ProductCard/ProductCard';
import './SubcategoryBlock.css';

function SubcategoryBlock({ category, productsByCat }) {
  productsByCat.sort(function (a, b) {
    return a.sort - b.sort;
  })

  return productsByCat.length > 0 ? (
    <li
      className="subcat-block"
      id={category.title.toLowerCase().replaceAll(' ', '-')}
    >
      <div className="subcat-block__title-box">
        {/* <img
          className="subcat-block__icon"
          src={`${PHOTO_URL}/${category.icon?.hash}${category.icon?.ext}`}
          alt=""
        /> */}
        <h2 className="subcat-block__title">{category.title}</h2>
      </div>

      <ul className="subcat-block__products">
        {productsByCat.map((item, i) => (
          <ProductCard product={item} index={i} key={item.title} />
        ))}
      </ul>
    </li>
  ) : null;
}

export default SubcategoryBlock;
