import { CircleArrowIcon, HeadingLogo, Logo } from '../../assets/icons/icons';
import Circle from '../Circle/Circle';
import ElementFlicker from '../ElementFlicker/ElementFlicker';
import ElementFromOpacity from '../ElementFromOpacity/ElementFromOpacity';
import ElementRotate from '../ElementRotate/ElementRotate';
import ElementsAnimationWrapper from '../ElementsAnimationWrapper/ElementsAnimationWrapper';
import ElementSlideFromTop from '../ElementSlideFromTop/ElementSlideFromTop';
import HalfCircle from '../HalfCircle/HalfCircle';
import Rhomb from '../Rhomb/Rhomb';
import RingWithShadow from '../RingWithShadow/RingWithShadow';
import LinkButton from '../LinkButton/LinkButton';
import SnapSection from '../SnapSection/SnapSection';
import './Heading.css';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { useEffect, useState } from 'react';

export default function Heading({ slideTo, activeIndex }) {
  const { width } = useWindowSize();
  const [elementInView, setElementIsInView] = useState(false)

  useEffect(() => {
    if (activeIndex === 0) {
      setElementIsInView(true)
    }
  }, [activeIndex])

  return (
    <SnapSection>
      <div className="heading">
        <div className="heading__container">
          <ElementFromOpacity>
            <div className="heading__content">
              <HeadingLogo
                mainClassName="heading__text-logo"
                fillClassName="heading__text-logo-fill"
              />

              <div className="heading__wrapper">
                <div className="heading__main-content">
                  <div className="heading__logo-wrapper">
                    <Logo
                      mainClassName="heading__logo"
                      fillClassName="heading__logo-fill"
                    />
                  </div>

                  <div className="heading__text-block">
                    <h1 className="heading__title">
                      Цифровое онлайн-меню для баров и ресторанов
                    </h1>
                    <p className="heading__subtitle">
                      Решение для оптимизации обслуживания и&nbsp;повышения
                      эффективности вашего заведения.
                    </p>
                  </div>

                  <div className="heading__link-button-wrapper">
                    <LinkButton id={'heading'} slideTo={slideTo} />
                  </div>
                </div>
              </div>

              <CircleArrowIcon
                mainClassName="heading__arrow"
                fillClassName="heading__arrow-fill"
              />
            </div>
          </ElementFromOpacity>

          <ElementSlideFromTop elementInView={elementInView}>
            <div className="heading__half-circle-wrapper">
              <HalfCircle
                color="var(--color-block-primary)"
                shadowColor="var(--color-block-shadow)"
                rotateAngle={width > 900 ? '63.004deg' : '153.004deg'}
              />
            </div>

            <div className="heading__ring-wrapper">
              <RingWithShadow
                color="var(--color-block-primary)"
                innerColor="var(--color-block-active)"
                shadowColor="var(--color-block-shadow)"
              />
            </div>

            <div className="heading__rhomb-wrapper">
              <Rhomb
                color="var(--color-block-primary)"
                shadowColor="var(--color-block-shadow)"
              />
            </div>

            <div className="heading__mobile-half-circle-wrapper">
              <HalfCircle
                color="var(--color-block-primary)"
                shadowColor="var(--color-block-shadow)"
                rotateAngle="45deg"
              />
            </div>
          </ElementSlideFromTop>

          <ElementsAnimationWrapper>
            <div className="heading__flicker-wrapper">
              <ElementFlicker elementInView={elementInView}>
                <Circle color="var(--color-block-secondary)" />
              </ElementFlicker>
            </div>

            <div className="heading__rotate-wrapper">
              <ElementRotate elementInView={elementInView}>
                <HalfCircle
                  color="var(--color-block-secondary)"
                  rotateAngle={width > 900 ? '60deg' : '150deg'}
                />
              </ElementRotate>
            </div>
          </ElementsAnimationWrapper>
        </div>
      </div>
    </SnapSection>
  );
}
