import ElementFromOpacity from '../../ElementFromOpacity/ElementFromOpacity';
import ElementSlideFromLeft from '../../ElementSlideFromLeft/ElementSlideFromLeft';
import './ImageElement.css';

export default function ImageElement({ children }) {
  return (
    <ElementFromOpacity>
      <div className="image-element">{children}</div>
    </ElementFromOpacity>
  );
}
