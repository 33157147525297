import { useEffect, useState } from 'react';
import './TariffCardForm.css';
import { CloseIcon } from '../../../assets/icons/icons';
import TarrifType from '../TarrifType/TarrifType';
import Input from '../../Input/Input';
import Checkbox from '../../Checkbox/Checkbox';
import usePhoneFormat from '../../../assets/hooks/usePhoneFormat';
import useEmailValidator from '../../../assets/hooks/useEmailValidator';
import mainApi from '../../../assets/api/MainApi';
import {
  ERROR_POPUP_ID,
  STORAGE_KEY,
  SUCCESS_POPUP_ID,
} from '../../../assets/utils/constants';
import { getUTMHistoryString } from "../../../assets/utils/utils";

const initialValues = {
  name: '',
  phone: '',
  email: '',
  consent: false,
};

const initialValuesValidity = {
  name: { validState: false, errorMessage: '' },
  phone: { validState: false, errorMessage: '' },
  email: { validState: false, errorMessage: '' },
  consent: { validState: false, errorMessage: '' },
};

export default function TariffCardForm({
  title,
  price,
  tariff,
  handleBack,
  isFormValid,
  setIsFormValid,
  isPreloader,
  setIsPreloader,
}) {
  const { formatPhoneNumber, deletePhoneNumber } = usePhoneFormat();
  const { validateEmail } = useEmailValidator();
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const successPopup = document.getElementById(SUCCESS_POPUP_ID);
  const errorPopup = document.getElementById(ERROR_POPUP_ID);

  useEffect(() => {
    const isValid = Object.values(valuesValidity).every(
      (value) => value.validState
    );
    setIsFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesValidity]);

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const type = input.type;
    const value = type === 'checkbox' ? input.checked : input.value;

    switch (name) {
      case 'phone':
        const { formattedValue, validity: phoneValidity } =
          formatPhoneNumber(value);
        setValues({ ...values, [name]: formattedValue });
        setValuesValidity({
          ...valuesValidity,
          [name]: phoneValidity,
        });
        break;

      case 'email':
        const { validity: emailValidity } = validateEmail({ value, e });
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: emailValidity,
        });
        break;

      default:
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: { validState: Boolean(value), errorMessage: '' },
        });
    }
  }

  function handlePhoneDelete(e) {
    const { formattedValue, validity } = deletePhoneNumber({
      e,
      value: values.phone,
      validity: valuesValidity.phone,
    });

    setValues((prevValue) => ({
      ...prevValue,
      phone: formattedValue,
    }));
    setValuesValidity((prevValue) => ({
      ...prevValue,
      phone: validity,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isFormValid || isPreloader) return;
    let referrer = localStorage.getItem('referrer') ? localStorage.getItem('referrer') : 'Direct';
    if (referrer.includes('cabafs.ru')) {
      referrer = 'Direct';
    }
    const utm_history = getUTMHistoryString();

    setIsPreloader(true);
    mainApi
      .sendContactForm({
        name: values.name,
        phone: values.phone.replace(/\D/g, ''),
        email: values.email,
        referrer: referrer,
        clicked_btn: localStorage.getItem(STORAGE_KEY),
        utm: utm_history ? utm_history : 'local',
        tariff,
      })
      .then(() => {
        setValues(initialValues);
        setValuesValidity(initialValuesValidity);
        handleBack();
        successPopup?.showPopover();
      })
      .catch((err) => {
        console.log(err);
        errorPopup?.showPopover();
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <div className="tariff-card-form">
      <div className="tariff-card-form__title-box">
        <p className="tariff-card-form__title">Оформление заказа</p>
        <button
          className="tariff-card-form__back-button"
          type="button"
          onClick={handleBack}
        >
          <CloseIcon
            mainClassName="tariff-card-form__back-icon"
            fillClassName="tariff-card-form__back-icon-fill"
          />
        </button>
      </div>

      <div className="tariff-card-form__type-box">
        <TarrifType title={title} price={price} />
      </div>

      <form
        className="tariff-card-form__form"
        id="tariff-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="tariff-card-form__input-box tariff-card-form__input-box_type_wide">
          <Input
            label="Имя"
            name="name"
            value={values.name}
            autoComplete="name"
            onChange={handleChange}
          />
        </div>

        <Input
          label="Телефон"
          name="phone"
          type="tel"
          value={values.phone}
          autoComplete="tel"
          onChange={handleChange}
          onKeyDown={handlePhoneDelete}
        />

        <Input
          label="Эл. почта"
          name="email"
          type="email"
          value={values.email}
          autoComplete="email"
          onChange={handleChange}
        />

        <div className="tariff-card-form__input-box tariff-card-form__input-box_type_wide tariff-card-form__input-box_type_checkbox">
          <Checkbox
            label="Даю свое согласие на обработку <a href='#'>персональных&nbsp;данных</a>"
            name="consent"
            id={tariff}
            checked={values.consent}
            onChange={handleChange}
          />
        </div>
      </form>
    </div>
  );
}
